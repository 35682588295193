import { connect } from "react-redux";
import { addToNamespace } from "../utils/addToNamespace";
import { namespace } from "./constants";
import { TAB_BAR_ACTIONS } from "../tabbar/constants";
import { namespace as tabBarNamespace } from "../tabbar/constants";
import { namespace as dagNamespace } from "./constants";
import { DAG_ACTIONS } from "./constants";

const TAB_BAR_ACTION_TYPES = addToNamespace(tabBarNamespace, TAB_BAR_ACTIONS);

const DAG_ACTION_TYPES = addToNamespace(dagNamespace, DAG_ACTIONS);

const actions = {
  showTaskDetails: {
    type: DAG_ACTION_TYPES.TASK_DETAILS_SHOW
  },
  hideTaskDetails: {
    type: DAG_ACTION_TYPES.TASK_DETAILS_HIDE
  },
  setSelectedRows: {
    type: DAG_ACTION_TYPES.SET_SELECTED_ROWS
  }
};

const actionCreators = {
  add: payload => ({
    ...payload,
    ...{
      type: TAB_BAR_ACTION_TYPES.ADD
    }
  }),
  setActiveTab: payload => ({ ...payload, ...actions.setActiveTab }),
  showTaskDetails: payload => ({ ...payload, ...actions.showTaskDetails }),
  hideTaskDetails: () => ({ ...actions.hideTaskDetails }),
  setSelectedRows: payload => ({ ...payload, ...actions.setSelectedRows })
};

export function createDucker({ namespace }) {
  const defaultState = {
    selectedRows: {},
    isDrawerOpen: false
  };

  const selectors = state => ({
    tabs: state => state.tabs
  });

  function reducer(state = defaultState, action) {
    switch (action.type) {
      case DAG_ACTION_TYPES.TASK_DETAILS_SHOW:
        return {
          ...state,
          isDrawerOpen: true,
          ...action
        };

      case DAG_ACTION_TYPES.TASK_DETAILS_HIDE:
        return {
          ...state,
          isDrawerOpen: false
        };

      case DAG_ACTION_TYPES.SET_SELECTED_ROWS:
        console.log("SET_SELECTED_ROWS", action);
        return {
          ...state,
          selectedRows: {
            ...state.selectedRows,
            [action.tab]: action.payload
          }
        };

      default:
        return { ...state };
    }
  }

  const duck = {
    actionCreators,
    reducer,
    selectors
  };

  duck.connect = function(component) {
    return connect(
      state => {
        console.log("state", state);
        const activeTabLabel = state.tabBar.activeTab.label;
        let statusFilters;
        state.tabBar.tabs.map(i => {
          if (i.label === activeTabLabel) {
            statusFilters = { ...i.filters.status };
          }
          return i;
        });

        return {
          ...state.process,
          activeTabLabel: state.tabBar.activeTab.label,
          statusFilters: statusFilters,
          processId: state.tabBar.activeTab.label,
          executionDate: state.tabBar.activeTab.executionDate,
          refresh: state.refresh.refresh,
          startDate: state.date.startDate,
          endDate: state.date.endDate
        };
      },
      dispatch => ({
        onClick: payload => {
          dispatch(actionCreators.add({ payload }));
        },
        // showTaskDetailsDrawer: payload =>
        //   dispatch(actionCreators.showTaskDetails(payload)),
        hideProcessDetailsDrawer: () =>
          dispatch(actionCreators.hideTaskDetails()),
        onTaskClick: payload => {
          dispatch(actionCreators.showTaskDetails(payload));
        },
        setSelectedRows: payload => {
          dispatch(actionCreators.setSelectedRows(payload));
        }
      })
    )(component);
  };

  duck.composer = function() {};

  return duck;
}

export default createDucker({ namespace });
