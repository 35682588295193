import React, { Component } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core";
import { compose } from "recompose";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import UserDucker from "../navigation-bar/profile/Profile.ducker";

const styles = {
  root: {
    flexGrow: 1
  },
  tableRoot: {
    margin: "20px 0",
    "&>div": {
      boxShadow: "none"
    }
  }
};

export const GET_SHARED_PROJECT_USERS_LIST_QUERY = gql`
  query getProjectUsers($projectId: String!, $projectOwnerId: String!) {
    getProjectUsers(projectId: $projectId, projectOwnerId: $projectOwnerId) {
      firstName
      lastName
      email
    }
  }
`;

class UsersList extends Component {
  columns = [
    {
      title: "User",
      field: "firstName",
      defaultSort: "asc",
      render: ({ firstName, lastName }) => {
        return (
          <span style={{ marginLeft: 20 }}>{`${firstName} ${lastName}`}</span>
        );
      }
    },
    {
      title: "Email Address",
      field: "email"
    }
  ];

  render() {
    const { loading, error, data, classes } = this.props;
    if (error) {
      return <h1>Error {error}</h1>;
    }

    const { getProjectUsers } = data;
    if (loading) {
      return <LinearProgress style={{ marginTop: 10 }} />;
    }

    const hasProjectUsers = getProjectUsers && getProjectUsers.length > 0;

    return (
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12} className={classes.tableRoot}>
            {hasProjectUsers && (
              <MaterialTable
                title=""
                data={getProjectUsers}
                columns={this.columns}
                options={{
                  columnsButton: true,
                  // exportButton: true,
                  // selection: true,
                  pageSize: 11,
                  headerStyle: {
                    fontWeight: "bold"
                  },
                  rowStyle: {
                    height: 48,
                    "&:nthChild(even)": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)"
                    }
                  }
                }}
                onSelectionChange={payload => {
                  console.log("onSelectionChange", payload);
                  // setSelectedRows({ payload, tab: activeTabLabel });
                }}
                onRowClick={(event, row) => {
                  console.log("onRowClick", row);
                  // const { processId, executionDate } = row;

                  // const payload = {
                  //   label: processId,
                  //   processId,
                  //   executionDate,
                  //   typeProcess: "dag"
                  // };

                  // this.props.onTaskClick(payload);
                }}
              />
            )}

            {!hasProjectUsers && (
              <div>
                Click the button above to share the project with other members.
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

const UsersListWithStyles = compose(withStyles(styles))(UsersList);

const ProjectsQuery = ({ id, routeData = {} }) => (
  <Query
    query={GET_SHARED_PROJECT_USERS_LIST_QUERY}
    variables={{
      projectId: routeData.id,
      projectOwnerId: id
    }}
  >
    {({ loading, error, data }) => {
      if (loading) return "Loading...";
      if (error) return `Error! ${error.message}`;

      return <UsersListWithStyles data={data} />;
    }}
  </Query>
);

export default compose(
  withStyles(styles),
  UserDucker.connect
)(ProjectsQuery);
