import React, { useState } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core";
import { compose } from "recompose";
import gql from "graphql-tag";
import AddProjectDialog from "./AddProjectDialog";
// import AddInstanceDialog from "./AddInstanceDialog";
import AddNodeDialog from "./AddNodeDialog";
import Grid from "@material-ui/core/Grid";
import TabBar from "../tabbar/TabBar";
import SharedProjectUsersList from "./SharedProjectUsersList";
import ShareProjectDialog from "./ShareProjectDialog";
import UserDucker from "../navigation-bar/profile/Profile.ducker";
import { Query } from "react-apollo";
// import InstancesList from "./InstancesList";
import NodesList from "./NodesList";
import ProjectSettingsForm from "./ProjectSettingsForm";
// import InstanceActionDropdown from "./InstanceActionDropdown";
import get from "lodash.get";
import NodeActionDropdown from "./NodeActionDropdown";
const styles = {
  root: {
    flexGrow: 1,
    paddingTop: 20,
    backgroundColor: "#f4f3ef"
  },
  addProjectDialogButton: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: 20
  },
  addInstanceButton: {
    display: "flex",
    justifyContent: "flex-start",
    paddingLeft: 20,
    marginBottom: 20
  },
  contentWrapper: {
    margin: "0px 16px",
    paddingTop: 20,
    backgroundColor: "white",
    display: "flex"
  },
  title: {
    margin: "0 20px 20px 20px",
    display: "flex"
  },
  tabBarSection: {
    padding: 0
  }
};

export const GET_PROJECTS_QUERY = gql`
  query getProjects($userId: String!) {
    getProjects(userId: $userId) {
      id
      name
      description
    }
  }
`;

function ProjectsList(props) {
  const [activeTabLabel, setActiveTab] = useState("Instances");
  const [selectedInstances, setSelectedInstances] = useState([]);

  const onTabClick = e => {
    const label = e.currentTarget.getAttribute("data-tab-label");
    setActiveTab(label);
  };

  const { loading, error, classes, data, routeData, id } = props;
  if (error) {
    return <h1>Error {error}</h1>;
  }
  let project = null;
  if (routeData && routeData.id) {
    const projects = get(data, "getProjects", []);

    project = projects.filter(p => p.id === routeData.id)[0];
  }
  //  else {
  //   return (
  //     <div style={{ margin: "20px" }}>
  //       Please choose project from the "WORKSPACE" menu
  //     </div>
  //   );
  // }
  if (loading) {
    return <LinearProgress style={{ marginTop: 10 }} />;
  }

  const tabs = [
    { label: "Instances", isPermanent: true },
    { label: "Access", isPermanent: true },
    { label: "Settings", isPermanent: true }
  ];

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={9}>
          <span className={classes.title}>
            workspace/{project && project.name}
          </span>
        </Grid>
        <Grid item xs={3} className={classes.addProjectDialogButton}>
          <AddProjectDialog initialState={false} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <TabBar
            tabs={tabs}
            activeTabLabel={activeTabLabel}
            onTabClick={onTabClick}
            classes={{
              section: classes.tabBarSection
            }}
          />
          <section className={classes.contentWrapper}>
            {activeTabLabel === "Instances" && (
              <div>
                <section className={classes.addInstanceButton}>
                  <AddNodeDialog
                    initialState={false}
                    routeData={routeData}
                    userId={id}
                  />

                  {/* <AddInstanceDialog
                    initialState={false}
                    routeData={routeData}
                    userId={id}
                  /> */}
                </section>
                {/* {id && (
                  <InstanceActionDropdown
                    id={id}
                    routeData={routeData}
                    selectedInstances={selectedInstances}
                  />
                )} */}
                {id && (
                  <NodeActionDropdown
                    id={id}
                    routeData={routeData}
                    selectedInstances={selectedInstances}
                  />
                )}
                {id && (
                  <NodesList
                    id={id}
                    routeData={routeData}
                    style={{ marginTop: 20 }}
                    setSelectedInstances={setSelectedInstances}
                  />
                )}
              </div>
            )}
            {activeTabLabel === "Access" && (
              <div style={{ padding: "0 20px" }}>
                <ShareProjectDialog
                  initialState={false}
                  routeData={routeData}
                />
                <SharedProjectUsersList routeData={routeData} />
              </div>
            )}
            {activeTabLabel === "Settings" && (
              <ProjectSettingsForm routeData={routeData} userId={id} />
            )}
          </section>
        </Grid>
      </Grid>
    </div>
  );
}

const ProjectListWithStyles = compose(withStyles(styles))(ProjectsList);

const ProjectsQuery = ({ id, routeData }) => (
  <Query
    query={GET_PROJECTS_QUERY}
    variables={{
      userId: id
    }}
  >
    {({ loading, error, data }) => {
      // if (loading) return "Loading...";
      // if (error) return `Error! ${error.message}`;

      return (
        <ProjectListWithStyles data={data} id={id} routeData={routeData} />
      );
    }}
  </Query>
);

export default compose(UserDucker.connect)(ProjectsQuery);
