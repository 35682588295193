import { connect } from "react-redux";
import { addToNamespace } from "../utils/addToNamespace";
// import { DAG_ACTIONS } from "../dag-table/constants";
import { namespace as dagNamespace } from "../process-view/constants";
import { namespace as processStateBarNamespace } from "../process-state-bar/ducker";
import { TAB_BAR_ACTIONS } from "./constants";
import { STATUS_BAR_ACTIONS } from "../process-state-bar/ProcessStateBar.ducker";

export default function createDucker({ namespace }) {
  console.log("namespace", namespace, dagNamespace);
  const TAB_BAR_ACTION_TYPES = addToNamespace(namespace, TAB_BAR_ACTIONS);
  const STATUS_BAR_ACTIONS_TYPES = addToNamespace(
    processStateBarNamespace,
    STATUS_BAR_ACTIONS
  );
  //   const DAG_TABLE_ACTION_TYPES = addToNamespace(dagNamespace, DAG_ACTIONS);

  const actions = {
    add: {
      type: TAB_BAR_ACTION_TYPES.ADD
    },
    remove: {
      type: TAB_BAR_ACTION_TYPES.REMOVE
    },
    setActiveTab: {
      type: TAB_BAR_ACTION_TYPES.SET_ACTIVE_TAB
    },
    setProcessStateBar: {
      type: STATUS_BAR_ACTIONS_TYPES.UPDATE_BAR_STATE
    },
    showTaskDetails: {
      type: TAB_BAR_ACTION_TYPES.TASK_DETAILS_SHOW
    },
    hideTaskDetails: {
      type: TAB_BAR_ACTION_TYPES.TASK_DETAILS_HIDE
    }
  };

  const actionCreators = {
    add: payload => ({ ...payload, ...actions.add }),
    remove: payload => ({ ...payload, ...actions.remove }),
    setActiveTab: payload => ({ ...payload, ...actions.setActiveTab }),
    setProcessStateBar: payload => ({
      ...payload,
      ...actions.setProcessStateBar
    }),
    showTaskDetails: () => ({ ...actions.showTaskDetails }),
    hideTaskDetails: () => ({ ...actions.hideTaskDetails })
  };

  const defaultState = {
    tabs: [
      {
        label: "MAIN",
        isPermanent: true,
        filters: {
          status: {
            all: false
          }
        }
      }
    ],
    activeTab: {
      label: "MAIN"
    },
    isDrawerOpen: false
  };

  const selectors = state => ({
    tabs: state => state.tabs
  });

  function reducer(state = defaultState, action) {
    switch (action.type) {
      case TAB_BAR_ACTION_TYPES.ADD:
        const tabToAdd = action.payload;

        const tabExists =
          state.tabs.filter(tab => tab.label === tabToAdd.label).length > 0;
        if (tabExists) {
          return {
            ...state,
            activeTab: {
              ...state.activeTab,
              ...tabToAdd,
              processId: tabToAdd.processId,
              executionDate: tabToAdd.executionDate
            }
          };
        } else {
          tabToAdd.filters = { status: { all: false } };
        }
        return {
          ...state,
          tabs: [...state.tabs, tabToAdd],
          activeTab: {
            ...state.activeTab,
            ...tabToAdd,
            processId: tabToAdd.processId,
            executionDate: tabToAdd.executionDate
          }
        };

      case TAB_BAR_ACTION_TYPES.REMOVE:
        const tabToRemove = action.payload;
        const tabIndexToRemove = state.tabs
          .map((tab, index) => {
            if (tab.label === tabToRemove.label && !tab.isPermanent) {
              return index;
            }
            return -1;
          })
          .filter(i => i !== -1);

        if (tabIndexToRemove.length) {
          const index = tabIndexToRemove[0];
          let activeTabIndex =
            index < state.tabs.length - 1 ? index + 1 : index - 1;
          console.log("activeTabIndex", activeTabIndex);
          return {
            ...state,
            activeTab: {
              label: state.tabs[activeTabIndex].label
            },
            tabs: [
              ...state.tabs.slice(0, index),
              ...state.tabs.slice(index + 1)
            ]
          };
        }
        return {
          ...state
        };

      case TAB_BAR_ACTION_TYPES.SET_ACTIVE_TAB:
        return {
          ...state,
          activeTab: {
            ...state.activeTab,
            ...action.payload
          }
        };

      case TAB_BAR_ACTION_TYPES.FETCH_BY_PROCESS_STATUS:
        let activeTabLabel = state.activeTab.label;
        let tabs = [...state.tabs].map(tab => {
          if (tab.label === activeTabLabel) {
            tab.filters.status[action.payload] = !tab.filters.status[
              action.payload
            ];
            tab.filters.status["all"] = false;
          }
          return tab;
        });
        return {
          ...state,
          tabs
        };

      case TAB_BAR_ACTION_TYPES.FETCH_BY_ALL_PROCESS_STATUS:
        let updatedTabs = [...state.tabs].map(tab => {
          if (tab.label === state.activeTab.label) {
            const statusFilter = Object.keys(tab.filters.status).reduce(
              (statusFilter, filterKey) => {
                console.log(
                  "filterKey",
                  filterKey,
                  action.payload,
                  !tab.filters.status[filterKey]
                );
                if (filterKey === action.payload.toLowerCase()) {
                  statusFilter[filterKey] = !tab.filters.status[filterKey];
                } else {
                  statusFilter[filterKey] = false;
                }
                return statusFilter;
              },
              {}
            );
            console.log("statusFilter", statusFilter);
            tab.filters.status = statusFilter;
          }
          return tab;
        });
        return {
          ...state,
          tabs: updatedTabs
        };

      default:
        return { ...state };
    }
  }

  const duck = {
    actionCreators,
    reducer,
    selectors
  };

  duck.connect = function(component) {
    return connect(
      state => {
        return {
          ...state.tabBar,
          activeTabLabel: state.tabBar.activeTab.label
        };
      },
      dispatch => ({
        onClick: payload => dispatch(actionCreators.add({ payload })),
        onCloseClick: e => {
          e.stopPropagation();
          const label = e.currentTarget.getAttribute("data-tab-label");
          dispatch(actionCreators.remove({ payload: { label } }));
        },
        onTabClick: e => {
          console.log("ontabclick");
          const label = e.currentTarget.getAttribute("data-tab-label");
          const processId = e.currentTarget.getAttribute("data-process-id");
          const executionDate = e.currentTarget.getAttribute(
            "data-execution-date"
          );
          dispatch(
            actionCreators.setActiveTab({
              payload: { label, processId, executionDate }
            })
          );
          dispatch(actionCreators.setProcessStateBar({ payload: { label } }));
        }
      })
    )(component);
  };

  duck.composer = function() {};

  return duck;
}
