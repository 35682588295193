import { connect } from "react-redux";
import { addToNamespace } from "../utils/addToNamespace";

export const SNACKBAR_ACTIONS = {
  SNACKBAR_SHOW: "SHOW"
};

export const snackbarNamespace = "Snackbar";

const ACTION_TYPES = addToNamespace(snackbarNamespace, SNACKBAR_ACTIONS);

const actions = {
  showSnackBar: {
    type: ACTION_TYPES.SNACKBAR_SHOW
  }
};

export const actionCreators = {
  showSnackBar: payload => ({ ...payload, ...actions.showSnackBar })
};

export function createDucker() {
  const defaultState = {
    variant: "info",
    message: "",
    id: 0
  };

  function reducer(state = defaultState, action) {
    switch (action.type) {
      case ACTION_TYPES.SNACKBAR_SHOW:
        return {
          ...state,
          ...action.payload,
          id: state.id + 1
        };

      default:
        return { ...state };
    }
  }

  const duck = {
    actionCreators,
    reducer
  };

  duck.connect = function(component) {
    return connect(
      state => {
        return {
          ...state[snackbarNamespace.toLowerCase()]
        };
      },
      dispatch => ({
        showSnackBar: payload => {
          dispatch(actionCreators.showSnackBar({ payload }));
        }
      })
    )(component);
  };

  duck.composer = function() {};

  return duck;
}

export default createDucker();
