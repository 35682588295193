import React, { Component } from "react";
import MaterialTable from "material-table";
import LinearProgress from "@material-ui/core/LinearProgress";
import ProcessInfoDrawer from "../process-info-drawer/ProcessInfoDrawer.container";
import { withStyles } from "@material-ui/core";
import { compose } from "recompose";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { filterRowsByStatus } from "../process-list/ProcessList";
import classnames from "classnames";
import Warning from "@material-ui/icons/WarningRounded";

import { processListStyles } from "../process-list/ProcessList.style";

import { iconMap } from "../utils/iconMap";

export const GET_PROCESS_QUERY = gql`
  query getProcess($processId: String!, $executionDate: String!) {
    getProcess(processId: $processId, executionDate: $executionDate) {
      processId
      processType
      state
      executionDate
      startDate
      endDate
      duration
      operator
      processes {
        processId
        processType
        state
        executionDate
        startDate
        endDate
        duration
        operator
        upstreamTasks
        downstreamTasks
      }
    }
  }
`;

class ProcessDetails extends Component {
  columns = [
    {
      title: "Process ID",
      field: "processId"
    },
    {
      title: "TYPE",
      field: "processType"
    },
    {
      title: "State",
      field: "state",
      render: ({ state }) => {
        const { classes } = this.props;
        if (!state) {
          state = "failed";
        }
        const Icon = iconMap[state] || Warning;
        const iconClassName = classes[state] || classes.failed;

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Icon className={classnames(iconClassName, classes.icon)} />
            {state}
          </div>
        );
      }
    },
    {
      title: "Run",
      field: "executionDate"
    },
    {
      title: "Start Time",
      field: "startDate"
    },
    {
      title: "End Time",
      field: "endDate"
    },
    {
      title: "Duration",
      field: "duration"
    },
    {
      title: "Operator",
      field: "operator"
    }
  ];

  render() {
    const {
      isDrawerOpen,
      hideProcessDetailsDrawer,
      statusFilters,
      loading,
      error,
      data,
      activeTabLabel,
      setSelectedRows
    } = this.props;
    console.log("isDrawerOpen", isDrawerOpen);

    if (error) {
      return <h1>Error {error}</h1>;
    }
    const { getProcess } = data;
    if (loading || !getProcess) {
      return <LinearProgress style={{ marginTop: 10 }} />;
    }

    let { processes, ...parentProcess } = getProcess;

    // tasks = tasks.map(task => {
    //   task.parentProcessId = parentProcess.processId;
    //   return task;
    // });
    console.log("getProcess", processes, parentProcess);
    let rows = [parentProcess];
    if (processes && processes.length) {
      rows = rows.concat(processes);
    }
    // const rows = [parentProcess];
    const columns = this.columns;

    const filteredRows = filterRowsByStatus(rows, statusFilters);

    console.log("filteredRows", rows, filteredRows);
    // return <div>here</div>;

    return (
      <div>
        <MaterialTable
          title=""
          data={filteredRows}
          columns={columns}
          options={{
            columnsButton: true,
            // exportButton: true,
            selection: true,
            pageSize: 11,
            headerStyle: {
              fontWeight: "bold"
            }
          }}
          onSelectionChange={payload => {
            setSelectedRows({ payload, tab: activeTabLabel });
          }}
          onRowClick={(event, row) => {
            const {
              processId,
              executionDate,
              parentProcessId,
              processType
            } = row;

            const payload = {
              label: processId,
              processId,
              executionDate,
              typeProcess: processType.toLowerCase()
            };

            if (processType === "TASK") {
              payload.parentProcessId = parentProcessId;
            }
            this.props.onTaskClick(payload);
          }}
        />
        <ProcessInfoDrawer
          isOpen={isDrawerOpen}
          onCloseClick={hideProcessDetailsDrawer}
        />
      </div>
    );
  }
}

const ProcessDetailsQuery = ({
  processId,
  executionDate,
  typeProcess,
  ...rest
}) => (
  <Query
    query={GET_PROCESS_QUERY}
    variables={{
      processId,
      executionDate,
      typeProcess
    }}
    // fetchPolicy={"network-only"}
  >
    {({ loading, error, data }) => {
      console.log(rest);
      if (loading) return "Loading...";
      if (error) return `Error! ${error.message}`;

      console.log("in ProcessDetailsQuery", processId, executionDate);

      return <ProcessDetails {...rest} data={data} />;
    }}
  </Query>
);

export default compose(withStyles(processListStyles))(ProcessDetailsQuery);
