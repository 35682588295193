import React from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import ActionCheckboxSelect from "./ProcessActionSelect";

class ResponsiveDialog extends React.Component {
  state = {
    open: true
  };

  // handleClickOpen = e => {
  //   this.setState({ open: true });
  // };

  handleClose = e => {
    this.setState({ open: false });
  };

  handleRestartClose = (e, payload) => {
    this.setState({ open: false });
    const option = Object.keys(payload)
      .filter(key => payload[key] && key)
      .join(",");

    this.props.handleItemClick(e, {
      state: "restart",
      option,
      typeProcess: "task"
    });
  };

  render() {
    return (
      <Dialog
        fullScreen={false}
        open={this.state.open}
        onClose={this.handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Task action"}</DialogTitle>
        <ActionCheckboxSelect
          handleRestartClose={this.handleRestartClose}
          handleClose={this.handleClose}
        />
      </Dialog>
    );
  }
}

ResponsiveDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired
};

export default withMobileDialog()(ResponsiveDialog);
