import React, { Component } from "react";
import PropTypes from "prop-types";
import MaterialTable from "material-table";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core";
import { compose } from "recompose";
import gql from "graphql-tag";
import PercentBar from "../percent-bar/PercentBar";
import ProcessDetailsDrawer from "../process-info-drawer/ProcessInfoDrawer.container";
import classnames from "classnames";
import "./ProcessList.style.css";
import { processListStyles } from "./ProcessList.style";
import { iconMap } from "../utils/iconMap";
import { useQuery } from "@apollo/react-hooks";

export let PROCESSES_QUERY = gql`
  query getProcesses(
    $startDate: String
    $endDate: String
    $stackName: String
    $workspaceId: String
    $dns: String
  ) {
    getProcesses(
      startDate: $startDate
      endDate: $endDate
      stackName: $stackName
      workspaceId: $workspaceId
      dns: $dns
    ) {
      processId
      isPaused
      priority
      schedule
      state
      totalTasks
      tasksCompleted
      lastRun
      executionDate
    }
  }
`;

export class ProcessList extends Component {
  rowFactory = label => row => {
    let { [label]: field } = row;

    return <div>{field}</div>;
  };

  columns = [
    {
      title: "Priority",
      field: "priority",
      render: ({ priority }) => `P${priority}`
    },
    {
      title: "Status",
      field: "isPaused",
      render: ({ isPaused }) => (isPaused ? "Disabled" : "Enabled")
    },
    {
      title: "DAG",
      field: "processId",
      render: row => {
        const { processId, executionDate } = row;

        return (
          <div
            onClick={e => {
              e.stopPropagation();
              // console.log("row", row);
              this.props.onClick({
                label: processId,
                processId,
                executionDate
              });
            }}
            className={this.props.classes.link}
          >
            {processId}
          </div>
        );
      }
    },
    {
      title: "State",
      field: "state",
      defaultSort: "asc",
      render: ({ state }) => {
        const { classes } = this.props;
        const Icon = iconMap[state] || iconMap["failed"];
        const iconClassName = classes[state] || classes.failed;

        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              textTransform: "capitalize"
            }}
          >
            <Icon className={classnames(iconClassName, classes.icon)} />
            {state || "Inactive"}
          </div>
        );
      }
    },
    {
      title: "Progress",
      field: "progress",
      render: ({ tasksCompleted, totalTasks }) => {
        return (
          <PercentBar
            tasksCompleted={tasksCompleted}
            totalTasks={totalTasks}
            disablePercentDisplay={true}
          />
        );
      }
    },
    {
      title: "Schedule",
      field: "schedule"
    },
    {
      title: "Execution Date",
      field: "executionDate",
      defaultSort: "desc"
    },
    {
      title: "Last run",
      field: "lastRun"
    }
  ];

  render() {
    const {
      isDrawerOpen,
      hideProcessDetailsDrawer,
      statusFilters,
      data = { getProcesses: [] },
      setSelectedRows,
      refresh,
      activeTabLabel,
      classes
    } = this.props;

    const filteredRows = filterRowsByStatus(data.getProcesses, statusFilters);
    if (!filteredRows || filteredRows.length === 0) {
      return (
        <div>
          No Dags were found for given date range. Please try to adjust the
          range.
        </div>
      );
    }

    const filteredSortedRows = [];
    filteredRows.forEach(row => {
      if (row.state) {
        filteredSortedRows.unshift(row);
      } else {
        filteredSortedRows.push(row);
      }
    });

    console.log("filteredSortedRows", filteredSortedRows);

    return (
      <div className={classes.containerRoot}>
        <MaterialTable
          title=""
          data={filteredSortedRows}
          columns={this.columns}
          options={{
            columnsButton: true,
            // exportButton: true,
            selection: true,
            pageSize: 11,
            headerStyle: {
              fontWeight: "bold"
            },
            rowStyle: {
              height: 48,
              "&:nth-child(even)": {
                backgroundColor: "rgba(0, 0, 0, 0.04)"
              }
            }
          }}
          onSelectionChange={payload => {
            setSelectedRows({ payload, tab: activeTabLabel });
          }}
          onRowClick={(event, row) => {
            const { processId, executionDate } = row;

            const payload = {
              label: processId,
              processId,
              executionDate,
              typeProcess: "dag"
            };

            this.props.onTaskClick(payload);
          }}
        />

        <span style={{}}>{refresh}</span>
        <ProcessDetailsDrawer
          isOpen={isDrawerOpen}
          onCloseClick={hideProcessDetailsDrawer}
        />
      </div>
    );
  }
}

ProcessList.propTypes = {
  classes: PropTypes.shape({
    link: PropTypes.string
  }),
  isDrawerOpen: PropTypes.bool.isRequired,
  hideProcessDetailsDrawer: PropTypes.func.isRequired,
  statusFilters: PropTypes.shape({
    all: PropTypes.bool,
    running: PropTypes.bool,
    failed: PropTypes.bool,
    success: PropTypes.bool,
    scheduled: PropTypes.bool
  }).isRequired
};

ProcessList.defaultProps = {
  isDrawerOpen: false,
  hideProcessDetailsDrawer: () => {}
};

export function filterRowsByStatus(rows, statusFilters = {}) {
  let filteredRows = rows;
  let filters = Object.keys(statusFilters);
  // if status !== "all" and it is not there are "other" filter keys present in `statusFilters`
  if (!statusFilters.all && filters.length > 1) {
    // remove `all`
    // remove any 'false' values
    filters = filters
      .filter(statusKey => statusKey !== "all")
      .filter(statusKey => statusFilters[statusKey]);

    // if there are filters
    // we will reduce `rows` by evaluating `row.state` agains each individual filter
    if (filters.length) {
      filteredRows = filters.reduce((filteredRows, statusKey) => {
        const filteredByStatusRows = rows.filter(
          row => row.state === statusKey.toLowerCase()
        );
        filteredRows = filteredRows.concat(filteredByStatusRows);
        return filteredRows;
      }, []);
    }
  }
  return filteredRows;
}

const ProcessListWithQuery = ({
  refresh,
  startDate,
  endDate,
  stackName,
  workspaceId,
  nodeId,
  ...rest
}) => {
  const { loading, error, data } = useQuery(PROCESSES_QUERY, {
    variables: {
      // refresh,
      startDate,
      endDate,
      stackName,
      workspaceId,
      nodeId
    }
  });

  console.log(rest);
  if (error) {
    const errorMessage =
      "There was an issue fetching data. Our team is working on it 👍";
    return <h1>{errorMessage}</h1>;
  }
  if (loading) {
    return <LinearProgress style={{ marginTop: 10 }} />;
  }

  return <ProcessList {...rest} data={data} />;
};

export default compose(withStyles(processListStyles))(ProcessListWithQuery);
