import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
// import JobsTable from "../dag-table/Table.container";
import ProcessView from "../process-view/ProcessView.container";
import Signup from "../user/signup/Signup";
import Login from "../user/login/Login";
import Auth from "../user/auth/Auth";
import ConfirmAccount from "../user/confirm-account/ConfirmAccount";
import Confirmed from "../user/confirmed/Confirmed";
// import axios from "axios";
import Workspace from "../workspace/Workspace";
import get from "lodash.get";

const NoMatch = ({ location }) => (
  <div>
    <h3>
      No match for <code>{location.pathname}</code>
      <section>Ask Oleksiy to implement this section :)</section>
    </h3>
  </div>
);

const styles = {
  // section: {
  //   // marginLeft: 84,
  //   position: "relative",
  //   top: 15
  // }
};

// async function checkMe() {
//   let host = "",
//     response;
//   // eslint-disable-next-line
//   if (/\/\/localhost\:/.test(window.location.href)) {
//     host = "http://localhost:7878";
//   }
//   try {
//     response = await axios.get(`${host}/api/me`, {
//       withCredentials: true
//     });
//   } catch (err) {
//     console.log("registerUser error", err);
//   }
//   return response;
// }

class Main extends Component {
  state = {
    redirect: false,
    loading: true
  };
  // constructor(props) {
  //   super(props);
  //   // checkMe().then(response => {
  //   //   const data = {
  //   //     loading: false
  //   //   };
  //   //   if (response.data && response.data.success) {
  //   //     data.redirect = true;
  //   //   }
  //   //   this.setState(data);
  //   // });
  // }
  render() {
    // const { redirect, loading } = this.state;
    // if (loading) {
    //   return "loading...";
    // }
    // if (redirect) {
    //   return <ProcessView />;
    // }
    return (
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/signup" component={Signup} />
        <Route exact path="/confirmed" component={Confirmed} />
        <Route exact path="/confirm" component={ConfirmAccount} />
        <Route exact path="/login" component={Login} />
        <Route
          exact
          path="/workspaces"
          component={() => {
            return <Auth AuthSuccess={Workspace} AuthFailure={Login} />;
          }}
        />
        <Route
          exact
          path="/workspaces/:id"
          component={args => {
            const id = get(args, "match.params.id", 1);
            return (
              <Auth
                AuthSuccess={Workspace}
                AuthFailure={Login}
                routeData={{ id }}
              />
            );
          }}
        />
        <Route
          exact
          path="/dashboard/:workspaceId/:stackName"
          component={args => {
            const workspaceId = get(args, "match.params.workspaceId");
            const stackName = get(args, "match.params.stackName", "");
            return (
              <Auth
                AuthSuccess={ProcessView}
                AuthFailure={Login}
                stackName={stackName}
                workspaceId={workspaceId}
              />
            );
          }}
        />
        <Route component={NoMatch} />
      </Switch>
    );
  }
}

export default withStyles(styles)(Main);
