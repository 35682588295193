import React from "react";
import { Formik } from "formik";
import { compose } from "recompose";
import gql from "graphql-tag";
import { Query, Mutation } from "react-apollo";
import get from "lodash.get";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogActions from "@material-ui/core/DialogActions";
import FormControl from "@material-ui/core/FormControl";
import UserDucker from "../navigation-bar/profile/Profile.ducker";
import ProjectDeleteDialog from "./ProjectDeleteDialog";
import { GET_PROJECTS_QUERY } from "./Workspace";

export const GET_PROJECT_QUERY = gql`
  query getProject($userId: String!, $projectId: String!) {
    getProject(userId: $userId, projectId: $projectId) {
      id
      name
      description
      # environment
    }
  }
`;

export const UPDATE_PROJECT_MUTATION = gql`
  mutation UpdateProject(
    $userId: String!
    $projectId: String!
    $name: String!
    $description: String!
  ) {
    updateProject(
      userId: $userId
      projectId: $projectId
      name: $name
      description: $description
    ) {
      statusCode
      message
      data {
        name
      }
    }
  }
`;

const errorStyle = theme => ({
  error: {
    color: red[500],
    fontSize: 12
  }
});

function Error({ error, classes }) {
  return <span className={classes.error}>{error}</span>;
}

const ErrorWithStyles = withStyles(errorStyle)(Error);

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    margin: "0 20px"
  },
  input: {
    display: "flex",
    minWidth: 300,
    width: "100%",
    margin: "20px 0 0 0"
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  buttonsContainer: {
    marginTop: 30
  },
  submitButton: {
    boxShadow: "none"
  }
});

const Basic = ({ classes, initialValues, updateProject }) => (
  <Formik
    initialValues={initialValues}
    validate={values => {
      let errors = {};
      if (!values.name) {
        errors.name = "Required";
      }
      if (!values.description) {
        errors.description = "Required";
      }
      return errors;
    }}
    onSubmit={(values, { setSubmitting, setErrors }) => {
      const refetchQueries = [
        {
          query: GET_PROJECTS_QUERY,
          variables: {
            userId: initialValues.userId
          }
        }
      ];
      updateProject({
        variables: {
          ...values,
          userId: initialValues.userId,
          projectId: initialValues.projectId
        },
        refetchQueries
      }).then(res => {
        const statusCode = get(res, "data.updateProject.statusCode");
        const error = get(res, "data.updateProject.error");
        if (res.loading) {
          return;
        }
        if (error) {
          setErrors({ name: "Failed to update the project" });
          setSubmitting(false);
        }
        if (statusCode === 200) {
          setSubmitting(false);
        }
        console.log(res);
      });
    }}
  >
    {({
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting
    }) => (
      <form onSubmit={handleSubmit}>
        <FormControl className={classes.container} fullWidth>
          <TextField
            className={classes.input}
            type="text"
            label="Name"
            name="name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            variant="outlined"
          />
          <TextField
            className={classes.input}
            type="text"
            label="Description"
            name="description"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.description}
            variant="outlined"
          />
          {errors.description && touched.description && (
            <ErrorWithStyles error={errors.description} />
          )}
          <DialogActions className={classes.buttonsContainer}>
            <ProjectDeleteDialog {...initialValues} />
            <Button
              className={classes.submitButton}
              type="submit"
              disabled={isSubmitting}
              variant="contained"
              color="primary"
            >
              {isSubmitting && <CircularProgress size={24} />}
              {!isSubmitting && "Save"}
            </Button>
          </DialogActions>
        </FormControl>
      </form>
    )}
  </Formik>
);

const FormWithStyles = withStyles(styles)(Basic);

const UpdateFormWithMutation = ({ data, initialValues }) => (
  <Mutation mutation={UPDATE_PROJECT_MUTATION}>
    {(updateProject, { loading, error }) => (
      <FormWithStyles
        updateProject={updateProject}
        loading={loading}
        error={error}
        data={data}
        initialValues={initialValues}
      />
    )}
  </Mutation>
);

const initialValues = {
  name: "",
  description: "",
  projectId: "",
  userId: "",
  environment: "development"
};

const ProjectsQuery = ({ userId, routeData = {} }) => (
  <Query
    query={GET_PROJECT_QUERY}
    variables={{
      projectId: routeData.id,
      userId
    }}
  >
    {({ loading, error, data }) => {
      if (loading) return "Loading...";
      if (error) return `Error! ${error.message}`;

      const project = get(data, "getProject", {});

      return (
        <React.Fragment>
          <UpdateFormWithMutation
            data={data}
            initialValues={{
              ...initialValues,
              ...project,
              userId,
              ...{ projectId: routeData.id }
            }}
          />
        </React.Fragment>
      );
    }}
  </Query>
);

export default compose(UserDucker.connect)(ProjectsQuery);
