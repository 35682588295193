import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import GeneralInfo from "./ProcessGeneralInfo";
import InfoQuery from "./Info.query";
import CodeQuery from "./Code.query";
import LogQuery from "./Log.query";

import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

function CodeViewer(props) {
  return (
    <SyntaxHighlighter language="python" style={docco} wrapLines={true}>
      {props.code}
    </SyntaxHighlighter>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  }
});

class SimpleTabs extends React.Component {
  state = {
    value: 0
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const {
      isOpen,
      processId,
      executionDate,
      parentProcessId,
      typeProcess,
      tryNumber,
      metadata
    } = this.props;

    const { classes } = this.props;
    const { value } = this.state;

    if (!isOpen) {
      return <span />;
    }

    return (
      <div>
        <AppBar position="static" classes={{ root: classes.root }}>
          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={this.handleChange}
          >
            <Tab label="General" />
            <Tab label="Log" />
            <Tab label="Code" />
          </Tabs>
        </AppBar>
        {value === 0 && (
          <TabContainer>
            <InfoQuery
              processId={processId}
              typeProcess={typeProcess}
              parentProcessId={parentProcessId}
              executionDate={executionDate}
              Component={GeneralInfo}
            />
          </TabContainer>
        )}
        {value === 1 && (
          <TabContainer>
            <LogQuery
              processId={processId}
              parentProcessId={parentProcessId}
              executionDate={executionDate}
              tryNumber={tryNumber}
              metadata={metadata}
              Component={CodeViewer}
            />
          </TabContainer>
        )}
        {value === 2 && (
          <TabContainer>
            <CodeQuery
              processId={parentProcessId || processId}
              Component={CodeViewer}
            />
          </TabContainer>
        )}
      </div>
    );
  }
}

SimpleTabs.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SimpleTabs);
