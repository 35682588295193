import gql from "graphql-tag";

export const PROCESS_LOG_QUERY = gql`
  query getProcessLog(
    $parentProcessId: String
    $processId: String!
    $executionDate: String!
    $tryNumber: Int!
    $metadata: String!
  ) {
    getProcessLog(
      parentProcessId: $parentProcessId
      processId: $processId
      executionDate: $executionDate
      tryNumber: $tryNumber
      metadata: $metadata
    ) {
      tryNumber
      message
    }
  }
`;
