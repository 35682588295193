import React from "react";
import { withStyles } from "@material-ui/core/styles";
import ducker from "./ducker";
import Button from "@material-ui/core/Button";
import classnames from "classnames";
import { compose } from "recompose";
import gql from "graphql-tag";
import { Query } from "react-apollo";

const styles = {
  section: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
    zIndex: 100
  },
  button: {
    borderRadius: 0,
    border: "1px solid #e4e0e0",
    borderLeft: "none",
    backgroundColor: "#ffffff",
    whiteSpace: "nowrap"
  },
  isActive: {
    backgroundColor: "#f2f2f2",
    "&:hover": {
      backgroundColor: "#f2f2f2"
    }
  },
  leftBorder: {
    borderLeft: "1px solid #e4e0e0"
  },
  badge: {
    top: -11,
    width: 30,
    right: -11,
    height: 22,
    display: "flex",
    zIndex: 1,
    position: "absolute",
    flexWrap: "wrap",
    fontSize: "0.75rem",
    alignItems: "center",
    borderRadius: "40%",
    alignContent: "center",
    flexDirection: "row",
    justifyContent: "center",
    color: "#ffffff"
  }
};

const colorMap = {
  all: "#c9c9c9",
  running: "#ff9a1d",
  failed: "#ff0000",
  success: "#009a2c",
  skipped: "#009a2c",
  retry: "#009a2c",
  queued: "#009a2c",
  cleared: "#c9c9c9"
};

const TabBar = ({ classes = {}, tabs, onTabClick, data, filters }) => {
  let mappedData = {};
  if (data && data.globalProcessStatus) {
    mappedData = data.globalProcessStatus.reduce((prev, curr) => {
      prev[curr.status] = curr.count;
      return prev;
    }, {});
  }

  console.log("filters", filters);
  return (
    <section className={classes.section}>
      <span className={classes.leftBorder}>
        {tabs.map((tab, ii) => {
          if (mappedData[tab.status] === undefined) {
            return undefined;
          }
          const badgeStyles = {
            backgroundColor: colorMap[tab.label.toLowerCase()]
          };
          let badge = null;
          const count = mappedData[tab.status];
          if (count) {
            badge = (
              <div style={badgeStyles} className={classnames(classes.badge)}>
                {count}
              </div>
            );
          }

          console.log(tab.label.toLowerCase(), filters[tab.label]);

          return (
            <Button
              key={ii}
              onClick={onTabClick}
              data-tab-label={tab.label}
              data-process-id={tab.processId}
              data-execution-date={tab.executionDate}
              className={classnames(classes.button, {
                [classes.isActive]: filters[tab.label]
              })}
            >
              {tab.label}
              {badge}
            </Button>
          );
        })}
      </span>
    </section>
  );
};

export const PROCESSES_STATE_QUERY = gql`
  query globalProcessStatus($processId: String, $executionDate: String) {
    globalProcessStatus(processId: $processId, executionDate: $executionDate) {
      count
      status
    }
  }
`;

const processDetailsKeys = {
  all: 1,
  failed: 1,
  running: 1,
  success: 1,
  skipped: 1,
  retry: 1,
  cleared: 1,
  queued: 1
};

const processesKeys = {
  all: 1,
  failed: 1,
  running: 1,
  success: 1
};

const ProcessStateBarQuery = ({ processId, executionDate, ...rest }) => (
  <Query
    query={PROCESSES_STATE_QUERY}
    variables={{ processId, executionDate }}
    fetchPolicy={"network-only"}
  >
    {({ loading, error, data }) => {
      if (loading) return "Loading...";
      if (error) return `Error! ${error.message}`;

      console.log("in ProcessStateBarQuery", processId, executionDate);
      if (data && data.globalProcessStatus) {
        const keyMap = processId ? processDetailsKeys : processesKeys;

        data.globalProcessStatus = data.globalProcessStatus.filter(
          i => keyMap[i.status] && i
        );
      }
      return <TabBar {...rest} data={data} />;
    }}
  </Query>
);

export default compose(
  // graphql(processStateQuery),
  ducker.connect,
  withStyles(styles)
)(ProcessStateBarQuery);
