import { connect } from "react-redux";
import { addToNamespace } from "../utils/addToNamespace";
import {
  TAB_BAR_ACTIONS,
  namespace as tabBarNamespace
} from "../tabbar/constants";

export const STATUS_BAR_ACTIONS = {
  TOGGLE_TAB: "TOGGLE_TAB",
  UPDATE_BAR_STATE: "UPDATE_BAR_STATE"
};
export default function createDucker({ namespace }) {
  const TAB_BAR_ACTION_TYPES = addToNamespace(tabBarNamespace, TAB_BAR_ACTIONS);
  const STATUS_BAR_ACTION_TYPES = addToNamespace(namespace, STATUS_BAR_ACTIONS);

  const actions = {
    SET_ACTIVE: {
      type: STATUS_BAR_ACTION_TYPES.TOGGLE_TAB
    },
    FETCH_BY_PROCESS_STATUS: {
      type: TAB_BAR_ACTION_TYPES.FETCH_BY_PROCESS_STATUS
    },
    FETCH_BY_ALL_PROCESS_STATUS: {
      type: TAB_BAR_ACTION_TYPES.FETCH_BY_ALL_PROCESS_STATUS
    }
  };

  const actionCreators = {
    setActiveTab: payload => ({ ...payload, ...actions.SET_ACTIVE }),
    fetchByProcessStatus: payload => ({
      ...payload,
      ...actions.FETCH_BY_PROCESS_STATUS
    }),
    fetchByAllProcessStatus: payload => ({
      ...payload,
      ...actions.FETCH_BY_ALL_PROCESS_STATUS
    })
  };
  const ALL_LABEL = "all";

  const defaultState = {
    tabs: [
      {
        label: ALL_LABEL,
        status: "all",
        count: null,
        isActive: false
      },
      {
        label: "success",
        status: "success",
        count: null,
        isActive: false
      },
      {
        label: "failed",
        status: "failed",
        count: null,
        isActive: false
      },
      {
        label: "running",
        status: "running",
        count: null,
        isActive: false
      },
      {
        label: "skipped",
        status: "skipped",
        count: null,
        isActive: false
      },
      {
        label: "retry",
        status: "retry",
        count: null,
        isActive: false
      },
      {
        label: "queued",
        status: "queued",
        count: null,
        isActive: false
      },
      {
        label: "cleared",
        status: "cleared",
        count: null,
        isActive: false
      }
    ]
  };

  const selectors = state => ({
    tabs: state => state.tabs
  });

  function reducer(state = defaultState, action) {
    switch (action.type) {
      case STATUS_BAR_ACTION_TYPES.TOGGLE_TAB:
        const clickedLabel = action.payload;
        const hasSelectedAll = action.payload === ALL_LABEL;
        const tabs = state.tabs.map(tab => {
          if (tab.label === clickedLabel) {
            tab.isActive = !tab.isActive;
          }
          if (hasSelectedAll) {
            if (tab.label !== clickedLabel) {
              tab.isActive = false;
            }
          } else {
            if (tab.label === ALL_LABEL) {
              tab.isActive = false;
            }
          }
          return tab;
        });
        return { ...state, tabs };

      case STATUS_BAR_ACTION_TYPES.UPDATE_BAR_STATE:
        console.log(action.payload);
        const resetTabs = state.tabs.map(tab => {
          tab.isActive = false;
          return tab;
        });
        return { ...state, tabs: resetTabs };

      default:
        return { ...state };
    }
  }

  const duck = {
    actionCreators,
    reducer,
    selectors
  };

  duck.connect = function(component) {
    return connect(
      state => {
        return {
          tabs: state[namespace].tabs,
          activeTabLabel: state[namespace].activeTabLabel,
          filters: state.tabBar.tabs.filter(
            tab => tab.label === state.tabBar.activeTab.label
          )[0].filters.status,
          processId: state.tabBar.activeTab.processId,
          executionDate: state.tabBar.activeTab.executionDate
        };
      },
      dispatch => ({
        onTabClick: e => {
          const label = e.currentTarget.getAttribute("data-tab-label");
          dispatch(actionCreators.setActiveTab({ payload: label }));
          if (label.toLowerCase() === "all") {
            dispatch(
              actionCreators.fetchByAllProcessStatus({ payload: label })
            );
          } else {
            dispatch(actionCreators.fetchByProcessStatus({ payload: label }));
          }
        }
      })
    )(component);
  };

  duck.composer = function() {};
  return duck;
}
