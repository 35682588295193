import { createMuiTheme } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

export const borderColor = grey[200]
const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    palette: {
    },
});

export default theme;