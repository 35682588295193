import React from "react";

function PercentBar(props) {
  const {
    tasksCompleted,
    totalTasks,
    barWidth = "100%",
    disablePercentDisplay
  } = props;
  const success =
    totalTasks === 0 ? 0 : Math.floor((tasksCompleted * 100) / totalTasks);

  const score = success + "%";
  const color = "#35d3fb";
  return (
    <span style={{ display: "flex", alignItems: "center" }}>
      <span
        style={{
          width: barWidth,
          backgroundColor: "#ddd",
          height: 12,
          marginRight: 5
        }}
      >
        <span
          style={{
            textAlign: "left",
            padding: 1,
            // color: 'white',
            width: score,
            backgroundColor: color,
            height: 10,
            display: "flex",
            alignItems: "center"
          }}
        >
          {!disablePercentDisplay && <span>{score}</span>}
        </span>
      </span>
      <span>
        {tasksCompleted}/{totalTasks}
      </span>
    </span>
  );
}

export default PercentBar;
