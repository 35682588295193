export const DAG_ACTIONS = {
  FETCH: "FETCH",
  FETCH_MAIN: "FETCH_MAIN",
  FETCH_START: "FETCH_START",
  FETCH_END: "FETCH_END",
  FETCH_ERROR: "FETCH_ERROR",
  FETCH_SUCCESS: "FETCH_SUCCESS",
  TASK_DETAILS_SHOW: "TASK_DETAILS_SHOW",
  TASK_DETAILS_HIDE: "TASK_DETAILS_HIDE",
  SET_SELECTED_ROWS: "SET_SELECTED_ROWS"
};

export const namespace = "Dag";
