const MENU_SECTIONS = {
  dashboard: { navigationLabel: "DASHBOARD", navigationLink: "/dashboard" },
  workspace: {
    navigationLabel: "WORKSPACE",
    navigationLinks: []
  },
  // titan: { navigationLabel: "TITAN", navigationLink: "/titan" },
  // astra: { navigationLabel: "ASTRA", navigationLink: "/astra" },
  // omni: { navigationLabel: "OMNI", navigationLink: "/omni" },
  // components: {
  //   navigationLabel: "COMPONENTS",
  //   navigationLinks: [
  //     { navigationLabel: "TITAN", navigationLink: "/titan" },
  //     { navigationLabel: "ASTRA", navigationLink: "/astra" },
  //     { navigationLabel: "OMNI", navigationLink: "/omni" }
  //   ]
  // },
  alerts: { navigationLabel: "ALERTS & LOGS", navigationLink: "/alerts" },
  reports: { navigationLabel: "REPORTS", navigationLink: "/reports" }
};

export default MENU_SECTIONS;
