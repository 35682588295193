import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { withStyles } from "@material-ui/core";

import AddNodeForm from "./AddNodeForm";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const styles = {
  title: {
    paddingBottom: 0
  }
};

function AlertDialogSlide({ initialState = true, classes, routeData, userId }) {
  const [open, setOpen] = React.useState(initialState);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Add Node
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className={classes.title}>{"Add Node"}</DialogTitle>
        <DialogContent>
          <AddNodeForm
            handleClose={handleClose}
            routeData={routeData}
            userId={userId}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default withStyles(styles)(AlertDialogSlide);
