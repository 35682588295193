import { connect } from "react-redux";
import { addToNamespace } from "../utils/addToNamespace";
import { actionCreators as snackBarActionCreators } from "../snackbar/Snackbar.ducker";

export const REFRESH_ACTIONS = {
  REFRESH: "REFRESH"
};

const namespace = "refresh";
const REFRESH_ACTIONS_TYPES = addToNamespace(namespace, REFRESH_ACTIONS);

const actions = {
  REFRESH: {
    type: REFRESH_ACTIONS_TYPES.REFRESH
  }
};

export const actionCreators = {
  refresh: payload => ({ ...payload, ...actions.REFRESH })
};
function createDucker() {
  const defaultState = {
    refresh: 0
  };

  function reducer(state = defaultState, action) {
    switch (action.type) {
      case REFRESH_ACTIONS_TYPES.REFRESH:
        return { ...state, refresh: state.refresh + 1 };

      default:
        return { ...state };
    }
  }

  const duck = {
    actionCreators,
    reducer
  };

  duck.connect = function(component) {
    return connect(
      state => {
        return {
          ...state.date
        };
      },
      dispatch => ({
        refresh: e => {
          dispatch(actionCreators.refresh());
        },
        triggerSnackBarOnProcessAction: payload =>
          dispatch(snackBarActionCreators.showSnackBar({ payload }))
      })
    )(component);
  };

  return duck;
}

export default createDucker();
