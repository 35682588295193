import React from "react";
import { Formik } from "formik";
import { compose } from "recompose";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import get from "lodash.get";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogActions from "@material-ui/core/DialogActions";
import FormControl from "@material-ui/core/FormControl";
import UserDucker from "../navigation-bar/profile/Profile.ducker";
import classnames from "classnames";
import EnvironmentOptionsDropdown from "./EnvironmentOptionsDropdown";

import { GET_NODES_QUERY } from "./NodesList";
const ADD_NODE = gql`
  mutation AddNode(
    $host: String!
    $port: String!
    $description: String!
    $environment: String!
    $projectId: String!
  ) {
    addNode(
      host: $host
      port: $port
      description: $description
      environment: $environment
      projectId: $projectId
    ) {
      statusCode
      error
    }
  }
`;

const errorStyle = theme => ({
  error: {
    color: red[500],
    fontSize: 12
  }
});

function Error({ error, classes }) {
  return <span className={classes.error}>{error}</span>;
}

const ErrorWithStyles = withStyles(errorStyle)(Error);

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  input: {
    display: "flex",
    minWidth: 300,
    width: "100%",
    margin: "20px 0 0 0"
  },
  inputStack: {
    display: "flex",
    flexDirection: "column"
  },
  portInput: {
    width: 100
  },
  portContainer: {
    marginLeft: 20
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  buttonsContainer: {
    marginTop: 30
  },
  submitButton: {
    boxShadow: "none"
  }
});

const Basic = ({ mutate, handleClose, classes, id, routeData = {} }) => (
  <Formik
    initialValues={{
      host: "",
      description: "",
      environment: "DEVELOPMENT",
      port: "",
      projectId: routeData.id,
      userId: id,
      state: "INACTIVE"
    }}
    validate={values => {
      console.log("validate", values);
      let errors = {};
      if (!values.host) {
        errors.host = "Host is required";
      } else if (!values.port) {
        errors.port = "Port is required";
      } else if (!values.description) {
        errors.description = "Description required";
      }
      return errors;
    }}
    onSubmit={(values, { setSubmitting, setErrors }) => {
      const refetchQueries = [
        {
          query: GET_NODES_QUERY,
          variables: {
            projectId: routeData.id
          }
        }
      ];
      mutate({
        variables: {
          ...values,
          userId: id
        },
        refetchQueries
      })
        .then(res => {
          const statusCode = get(res, "data.addNode.statusCode");
          const error = get(res, "data.addNode.error");
          if (res.loading) {
            return;
          }
          if (error) {
            console.error("error", error);
            setErrors({ node: "Node with such name already exists" });
            setSubmitting(false);
          }
          if (statusCode === 200) {
            setSubmitting(false);
            handleClose();
          }
          console.log(res);
        })
        .catch(err => {
          console.log(err);
        });
    }}
  >
    {({
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting
      /* and other goodies */
    }) => (
      <form onSubmit={handleSubmit}>
        <FormControl fullWidth>
          <section className={classes.input}>
            <span className={classes.inputStack}>
              <TextField
                type="text"
                label="Host"
                name="host"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.host}
                variant="outlined"
              />
              {errors.host && touched.host && (
                <ErrorWithStyles error={errors.host} />
              )}
            </span>
            <span
              className={classnames(classes.inputStack, classes.portContainer)}
            >
              <TextField
                className={classes.portInput}
                type="text"
                label="Port"
                name="port"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.port}
                variant="outlined"
              />
              {errors.port && touched.port && (
                <ErrorWithStyles error={errors.port} />
              )}
            </span>
          </section>

          <TextField
            className={classes.input}
            classes={{ root: classes.input }}
            type="text"
            label="Description"
            name="description"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.description}
            variant="outlined"
          />
          {errors.description && touched.description && (
            <ErrorWithStyles error={errors.description} />
          )}

          <EnvironmentOptionsDropdown
            value={values.environment}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          {errors.environment && touched.environment && (
            <ErrorWithStyles error={errors.environment} />
          )}
          <DialogActions className={classes.buttonsContainer}>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              className={classes.submitButton}
              type="submit"
              disabled={isSubmitting}
              variant="contained"
              color="primary"
            >
              {isSubmitting && <CircularProgress size={24} />}
              {!isSubmitting && "Add"}
            </Button>
          </DialogActions>
        </FormControl>
      </form>
    )}
  </Formik>
);

export default compose(
  withStyles(styles),
  UserDucker.connect,
  graphql(ADD_NODE)
)(Basic);
