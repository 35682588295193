import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import DagDrawerTabs from "./ProcessDrawerTabs.container";

const styles = {
  list: {
    width: 250
  },
  fullList: {
    width: "auto"
  }
};

class TemporaryDrawer extends React.Component {
  state = {
    right: false
  };

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open
    });
  };

  componentWillReceiveProps(nextProps) {
    //console.log(nextProps && nextProps.data && nextProps.data.dag_id, this.props);

    const currentProcessId =
      this.props && this.props.data && this.props.data.dag_id;
    if (
      !this.props ||
      (nextProps &&
        nextProps.data &&
        nextProps.data.dag_id !== currentProcessId)
    ) {
      //Perform some operation
      this.setState({ right: true });
    } else {
      this.setState({ right: false });
    }
  }

  render() {
    const { isOpen, onCloseClick, classes } = this.props;

    console.log("Info", this.props);

    return (
      <div className={classes.root}>
        <Drawer anchor="right" open={isOpen} onClose={onCloseClick}>
          <div
            style={{ width: 450 }}
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer("right", false)}
            onKeyDown={this.toggleDrawer("right", false)}
          >
            <DagDrawerTabs isOpen={isOpen} />
          </div>
        </Drawer>
      </div>
    );
  }
}

TemporaryDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  processId: PropTypes.string.isRequired,
  executionDate: PropTypes.string.isRequired,
  parentProcessId: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onCloseClick: PropTypes.func.isRequired
};

export default withStyles(styles)(TemporaryDrawer);
