import React, { Component } from "react";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import AuthDucker from "./Auth.ducker";
import { compose } from "recompose";

import Sidenav from "../../sidenav/Sidenav";
import AppBar from "../../navigation-bar/NavigationBar";

const styles = theme => ({
  progress: {
    margin: theme.spacing.unit * 2
  },
  root: {
    position: "absolute",
    top: 0,
    left: 0
  },
  content: {
    marginTop: 64,
    height: "100%",
    zIndex: 1,
    display: "flex",
    position: "relative",
    flexGrow: 1
  },
  pageSection: {
    display: "flex",
    width: "100%"
  }
});

class Auth extends Component {
  state = {
    loading: true,
    error: "",
    test: "test auth"
  };
  constructor(props) {
    super(props);

    const { error, loading, ...payload } = this.state;

    console.log("Auth: checking /me");
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/me`, payload, {
        withCredentials: true
      })
      .then(response => {
        if (response) {
          const { data = {} } = response;
          const { success, error, user } = data;
          props.setActiveUser(user);
          this.setState({ success, error, user, loading: false });
        }
        console.log("/me", response);
      })
      .catch(err => {
        console.log("/me error", err);
      });
  }
  render() {
    const {
      AuthSuccess,
      AuthFailure,
      classes,
      setActiveUser,
      ...rest
    } = this.props;
    const { loading, success, error, user } = this.state;

    if (error) {
      return <AuthFailure />;
    } else if (success) {
      if (setActiveUser) {
        console.log("setActiveUser", setActiveUser, user);
      }
      return (
        <React.Fragment>
          <AppBar className={{ root: classes.root }} title="Titan" />
          <div className={classes.content}>
            <Sidenav userId={user && user.id} />
            <section className={classes.pageSection}>
              <AuthSuccess {...rest} />
            </section>
          </div>
        </React.Fragment>
      );
    } else if (loading) {
      return <CircularProgress className={classes.progress} />;
    }
  }
}

export default compose(
  withStyles(styles),
  AuthDucker.connect
)(Auth);
