import React, { Component } from "react";
import ProcessList from "../process-list/ProcessList";
import ProcessDetails from "../process-details/ProcessDetails";

import DatePickerWrapper from "../date-picker-wrapper/DatePickerWrapper";
import ProcessActionDropdown from "../process-action-dropdown/ProcessActionDropdown";
import TabBar from "../tabbar/TabBarTitanContainer";
import ProcessStateBar from "../process-state-bar/ProcessStateBar";
import RefreshButton from "../refresh-button/RefreshButton";
import { withStyles } from "@material-ui/core/styles";
import Snackbar from "../snackbar/Snackbar";

class ProcessView extends Component {
  render() {
    const { activeTabLabel } = this.props;
    const isMainView = activeTabLabel === "MAIN";
    console.log("PROSP", this.props);

    if (!isMainView) {
      return <ProcessDetails {...this.props} />;
    }

    return <ProcessList {...this.props} />;
  }
}

const styles = {
  root: {
    position: "absolute",
    top: 0,
    left: 0
  },
  content: {
    marginTop: 64,
    height: "100%",
    zIndex: 1,
    display: "flex",
    position: "relative",
    flexGrow: 1
  },
  section: {
    marginLeft: 10,
    position: "relative",
    top: 15
  },
  actionsWrapper: {
    display: "flex",
    alignItems: "center",
    paddingLeft: 10
  }
};

class AppView extends Component {
  render() {
    const { classes, activeTabLabel } = this.props;
    return (
      <React.Fragment>
        <section>
          <Snackbar />
          <div style={{ paddingTop: 20 }}>
            <TabBar />
          </div>
          <div className={classes.actionsWrapper}>
            <ProcessActionDropdown />
            <RefreshButton />
            {activeTabLabel === "MAIN" && <DatePickerWrapper />}

            <ProcessStateBar />
          </div>
          <section className={classes.section}>
            <ProcessView {...this.props} />
          </section>
        </section>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(AppView);
