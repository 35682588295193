import React, { Component } from "react";
import onClickOutside from "react-onclickoutside";
import { withStyles } from "@material-ui/core/styles";
// import classnames from "classnames";
import { compose } from "recompose";
import FormControl from "@material-ui/core/FormControl";
// import DatePicker from "../date-picker/DatePicker";
import DateDropdown from "./DateDropdown";
// import "./style.css";

const styles = {
  root: {
    position: "absolute",
    zIndex: 10
  },
  wrapper: {
    marginRight: 20
  },
  overlay: {
    position: "absolute",
    width: 201,
    height: 40,
    zIndex: 11
  }
};

class DatePickerWrapper extends Component {
  state = {
    isOpen: false
  };

  handleClick = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  handleClickOutside = evt => {
    this.setState({
      isOpen: false
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <FormControl className={classes.wrapper}>
        <DateDropdown />
        {/* <DatePicker handleClickOutside={this.handleClickOutside} /> */}
        {/* <section
          className={classnames("date-picker-wrapper", classes.root, {
            active: this.state.isOpen
          })}
        >
          <section
            className={classnames(classes.overlay)}
            // onClick={this.handleClick}
          />
        </section> */}
        {/* <span
          className={classnames("date-picker-active-top-border", {
            active: this.state.isOpen
          })} */}
        {/* /> */}
      </FormControl>
    );
  }
}
export default compose(
  withStyles(styles),
  onClickOutside
)(DatePickerWrapper);
