import React from "react";
import { withStyles } from "@material-ui/core";

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "2em",
    textAlign: "center"
  }
};

export function ConfirmAccount({ classes }) {
  return (
    <section className={classes.root}>
      <h4>Congratulations! You are one step away from success!</h4>
      <p>
        We have sent a verification email to you email address. Please follow
        the instructions in the email to verify your account.
      </p>
    </section>
  );
}

export default withStyles(styles)(ConfirmAccount);
