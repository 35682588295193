import React from "react";
import { Query } from "react-apollo";
import { PROCESS_DETAILS_QUERY } from "./Info.graphql.query";
import LinearProgress from "@material-ui/core/LinearProgress";

export default function Launches(props) {
  const {
    processId,
    executionDate,
    typeProcess,
    parentProcessId,
    Component
  } = props;
  return (
    <Query
      query={PROCESS_DETAILS_QUERY}
      variables={{ processId, executionDate, typeProcess, parentProcessId }}
    >
      {({ loading, error, data }) => {
        if (error) {
          console.log(error);
          return <h1>Error </h1>;
        }
        if (loading) {
          return <LinearProgress style={{ marginTop: 10 }} />;
        }

        const response = data.processDetails;

        if (!(response && response.processId)) {
          return <h2>There was a problem with the request.</h2>;
        }
        return (
          <Component
            {...response}
            parentData={{
              processId,
              executionDate,
              typeProcess,
              parentProcessId
            }}
          />
        );
      }}
    </Query>
  );
}
