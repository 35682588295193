import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import NavMenu from "./navMenu/NavMenu";
import NavLink from "./navLink/NavLink";
import MENU_SECTIONS from "./sidenav.sections";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
// import Search from "@material-ui/icons/Search";
import Dashboard from "@material-ui/icons/Dashboard";
import Notifications from "@material-ui/icons/Notifications";
import Fingerprint from "@material-ui/icons/Fingerprint";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import InsertDriveFile from "@material-ui/icons/InsertDriveFile";
import classnames from "classnames";
import { Query } from "react-apollo";
import gql from "graphql-tag";

const styles = {
  list: {
    width: 250,
    borderLeft: "#f7f7f7",
    position: "relative",
    height: "calc(100vh - 64px)",
    borderRight: "1px solid #eceaea",
    backgroundColor: "#000000de",
    top: 0,
    flex: "0 0 auto"
  },
  fullList: {
    width: "auto"
  },
  navControl: {
    left: 0,
    width: "100%",
    top: "94%",
    height: 56,
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    borderTop: "1px solid #272727",
    color: "#bdbbbb",
    "&:hover": {
      cursor: "pointer"
    }
  },
  navControlIcon: {
    marginRight: 19,
    transition: "0.2s"
  },
  isExpanded: {
    width: 250,
    transition: "width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms"
  },
  isCollapsed: {
    width: 60,
    overflow: "hidden",
    transition: "width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms"
  },
  rootLink: {
    padding: 0
  },
  rotateControlIcon: {
    transform: "rotate(180deg)"
  }
};

function findSelectedPath(path) {
  if (!path) {
    return;
  }
  let selectedPaths = [];
  Object.keys(MENU_SECTIONS).forEach(key => {
    const section = MENU_SECTIONS[key];
    const navigationLinks = section.navigationLinks;
    let subsection = [];
    if (navigationLinks) {
      navigationLinks.forEach(link => {
        if (path === link.navigationLink) {
          subsection = [link.navigationLabel];
        }
      });

      if (subsection.length && section.navigationLabel) {
        subsection.unshift(section.navigationLabel);
      }
    } else if (section.navigationLink === path) {
      subsection = [section.navigationLabel];
    }
    if (subsection.length) {
      selectedPaths = subsection;
    }
  });

  return selectedPaths;
}

class Sidenav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      userExpandedMenuState: null,
      isExpanded: false
    };
  }

  componentDidMount() {
    this.routerSelected = findSelectedPath(window.location.pathname);
    this.routerSelected && this.setState({ selected: this.routerSelected });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.routerSelected = findSelectedPath(this.props.location.pathname);
      this.setState({
        selected: this.routerSelected,
        userExpandedMenuState: null
      });
    }
  }

  toggleSidenav = () => {
    this.setState({ isExpanded: !this.state.isExpanded });
  };

  onMenuClick = (label, shouldReset) => {
    let state = {};

    if (shouldReset) {
      state = { userExpandedMenuState: [] };
    } else {
      if (label === this.routerSelected[0]) {
        state = {
          selected: this.routerSelected,
          userExpandedMenuState: null,
          isExpanded: true
        };
      } else {
        state = { userExpandedMenuState: [label], isExpanded: true };
      }
    }
    this.setState(state);
  };

  render() {
    const { classes, data } = this.props;
    const selected = this.state.userExpandedMenuState || this.state.selected;
    const isExpanded = this.state.isExpanded;

    let userProjectsLinks = [];
    if (data && data.getProjects) {
      userProjectsLinks = data.getProjects.map(project => ({
        navigationLabel: project.name,
        navigationLink: `/workspaces/${project.id}/`
      }));
    }
    console.log("sidenav", userProjectsLinks);

    return (
      <div
        className={classnames(classes.list, {
          [classes.isExpanded]: isExpanded,
          [classes.isCollapsed]: !isExpanded
        })}
      >
        <NavLink
          isExpanded={isExpanded}
          className={classes.rootLink}
          Icon={Fingerprint}
          routerSelected={this.routerSelected}
          navigationLink={MENU_SECTIONS.dashboard.navigationLink}
          navigationLabel={MENU_SECTIONS.dashboard.navigationLabel}
        />
        <NavMenu
          isExpanded={isExpanded}
          Icon={Dashboard}
          selected={selected}
          routerSelected={this.routerSelected}
          onMenuClick={this.onMenuClick}
          navigationLinks={userProjectsLinks}
          navigationLabel={MENU_SECTIONS.workspace.navigationLabel}
        />
        {/* <NavLink
          isExpanded={isExpanded}
          className={classes.rootLink}
          Icon={Fingerprint}
          routerSelected={this.routerSelected}
          navigationLink={MENU_SECTIONS.titan.navigationLink}
          navigationLabel={MENU_SECTIONS.titan.navigationLabel}
        />
        <NavLink
          isExpanded={isExpanded}
          className={classes.rootLink}
          Icon={Fingerprint}
          routerSelected={this.routerSelected}
          navigationLink={MENU_SECTIONS.astra.navigationLink}
          navigationLabel={MENU_SECTIONS.astra.navigationLabel}
        />
        <NavLink
          isExpanded={isExpanded}
          className={classes.rootLink}
          Icon={Fingerprint}
          routerSelected={this.routerSelected}
          navigationLink={MENU_SECTIONS.omni.navigationLink}
          navigationLabel={MENU_SECTIONS.omni.navigationLabel}
        /> */}
        {/* <NavMenu
          isExpanded={isExpanded}
          Icon={Search}
          selected={selected}
          routerSelected={this.routerSelected}
          onMenuClick={this.onMenuClick}
          navigationLinks={MENU_SECTIONS.components.navigationLinks}
          navigationLabel={MENU_SECTIONS.components.navigationLabel}
        /> */}
        <NavLink
          isExpanded={isExpanded}
          className={classes.rootLink}
          Icon={Notifications}
          routerSelected={this.routerSelected}
          navigationLink={MENU_SECTIONS.alerts.navigationLink}
          navigationLabel={MENU_SECTIONS.alerts.navigationLabel}
        />
        <NavLink
          isExpanded={isExpanded}
          className={classes.rootLink}
          Icon={InsertDriveFile}
          routerSelected={this.routerSelected}
          navigationLink={MENU_SECTIONS.reports.navigationLink}
          navigationLabel={MENU_SECTIONS.reports.navigationLabel}
        />

        <div onClick={this.toggleSidenav} className={classes.navControl}>
          <ChevronLeft
            className={classnames(
              classes.navControlIcon,
              !isExpanded && classes.rotateControlIcon
            )}
          />
        </div>
      </div>
    );
  }
}

Sidenav.propTypes = {
  classes: PropTypes.object.isRequired,
  routerSelected: PropTypes.array
};

export const GET_PROJECTS_QUERY = gql`
  query getProjects($userId: String!) {
    getProjects(userId: $userId) {
      id
      name
    }
  }
`;

const SidenavWithStyles = withStyles(styles)(Sidenav);

const ProjectsQuery = ({ userId }) => (
  <Query
    query={GET_PROJECTS_QUERY}
    variables={{
      userId
    }}
  >
    {({ loading, error, data }) => {
      // if (loading) return "Loading...";
      // if (error) return `Error! ${error.message}`;

      return <SidenavWithStyles data={data} />;
    }}
  </Query>
);

export default compose(withRouter)(ProjectsQuery);
