import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Mutation } from "react-apollo";
import { withRouter } from "react-router-dom";
import gql from "graphql-tag";
import { GET_PROJECTS_QUERY } from "./Workspace";
import CircularProgress from "@material-ui/core/CircularProgress";
import { get } from "lodash";

export const DELETE_PROJECT_MUTATION = gql`
  mutation DeleteProject($userId: Int!, $projectId: Int!) {
    deleteProject(userId: $userId, projectId: $projectId) {
      statusCode
      message
      data {
        name
      }
    }
  }
`;

const DeleteMutation = ({ userId, projectId, history, handleClose }) => (
  <Mutation mutation={DELETE_PROJECT_MUTATION}>
    {(deleteProject, { loading, error }) => {
      return (
        <Button
          //className={classes.submitButton}
          type="submit"
          disabled={loading}
          variant="contained"
          color="secondary"
          onClick={() => {
            const refetchQueries = [
              {
                query: GET_PROJECTS_QUERY,
                variables: {
                  userId
                }
              }
            ];
            deleteProject({
              variables: {
                userId,
                projectId
              },
              refetchQueries
            }).then(res => {
              const statusCode = get(res, "data.deleteProject.statusCode");
              const error = get(res, "data.deleteProject.error");
              if (res.loading) {
                return;
              }

              if (statusCode === 200 && !error) {
                handleClose();
                history.push("/workspaces");
              }

              console.log("delete result", res);
            });
          }}
        >
          {loading && <CircularProgress size={24} />}
          {!loading && "Delete"}
        </Button>
      );
    }}
  </Mutation>
);

const DeleteMutationWithRouter = withRouter(DeleteMutation);

export default function AlertDialog({ name, userId, projectId }) {
  const [open, setOpen] = React.useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Delete
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Delete project "${name}"`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div>Are you sure you would like to delete this project?</div>
            <span>
              Warning: All active instances related to this project would be
              destroyed.
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>

          <DeleteMutationWithRouter
            userId={userId}
            projectId={projectId}
            handleClose={handleClose}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}
