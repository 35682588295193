import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DateTimePicker from "./DateTimePicker";

const ExpansionPanel = withStyles({
  root: {
    border: "1px solid rgba(0,0,0,.125)",
    boxShadow: "none",
    display: "flex",
    flexDirection: "column",
    padding: 0,
    "&:not(:last-child)": {
      borderBottom: 0
    },
    "&:before": {
      display: "none"
    },
    width: 332
  },
  expanded: {
    margin: "auto"
  }
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: "rgba(0,0,0,.03)",
    borderBottom: "1px solid rgba(0,0,0,.125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56
    }
  },
  content: {
    "&$expanded": {
      margin: "12px 0"
    }
  },
  expanded: {}
})(props => <MuiExpansionPanelSummary {...props} />);

ExpansionPanelSummary.muiName = "ExpansionPanelSummary";

const styles = {
  panelItem: {
    paddingLeft: 35,
    height: 34,
    borderBottom: "1px solid #ede9e9"
  },
  panelItemLast: {
    borderBottom: "none"
  }
};

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    // padding: theme.spacing.unit * 2,
    display: "flex",
    flexDirection: "column",
    padding: 0
  }
}))(MuiExpansionPanelDetails);

class CustomizedExpansionPanel extends React.Component {
  state = {
    expanded: ""
  };

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    });
  };

  render() {
    const { onClick, classes } = this.props;
    const { expanded } = this.state;
    return (
      <div>
        <ExpansionPanel
          square
          expanded={expanded === "panel1"}
          onChange={this.handleChange("panel1")}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>This</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <MenuItem
              classes={{ root: classes.panelItem }}
              onClick={onClick}
              targettime="thisWeek"
            >
              This Week
            </MenuItem>
            <MenuItem
              classes={{ root: classes.panelItem }}
              onClick={onClick}
              targettime="thisMonth"
            >
              This Month
            </MenuItem>
            <MenuItem
              classes={{ root: classes.panelItem }}
              onClick={onClick}
              targettime="thisQuater"
            >
              This Quater
            </MenuItem>
            <MenuItem
              classes={{ root: classes.panelItem }}
              onClick={onClick}
              targettime="thisYear"
            >
              This Year
            </MenuItem>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          square
          expanded={expanded === "panel2"}
          onChange={this.handleChange("panel2")}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Last</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <MenuItem
              classes={{ root: classes.panelItem }}
              onClick={onClick}
              targettime="lastWeek"
            >
              Last Week
            </MenuItem>
            <MenuItem
              classes={{ root: classes.panelItem }}
              onClick={onClick}
              targettime="lastMonth"
            >
              Last Month
            </MenuItem>
            <MenuItem
              classes={{ root: classes.panelItem }}
              onClick={onClick}
              targettime="lastQuater"
            >
              Last Quater
            </MenuItem>
            <MenuItem
              classes={{ root: classes.panelItem }}
              onClick={onClick}
              targettime="lastYear"
            >
              Last Year
            </MenuItem>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          square
          expanded={expanded === "panel3"}
          onChange={this.handleChange("panel3")}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Last Timeframe</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <MenuItem
              classes={{ root: classes.panelItem }}
              onClick={onClick}
              targettime="last7Days"
            >
              Last 7 Days
            </MenuItem>
            <MenuItem
              classes={{ root: classes.panelItem }}
              onClick={onClick}
              targettime="last14Days"
            >
              Last 14 Days
            </MenuItem>
            <MenuItem
              classes={{ root: classes.panelItem }}
              onClick={onClick}
              targettime="last30Days"
            >
              Last 30 Days
            </MenuItem>
            <MenuItem
              classes={{ root: classes.panelItem }}
              onClick={onClick}
              targettime="last60Days"
            >
              Last 60 Days
            </MenuItem>
            <MenuItem
              classes={{ root: classes.panelItem }}
              onClick={onClick}
              targettime="last120Days"
            >
              Last 120 Days
            </MenuItem>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          className={classes.panelItemLast}
          square
          expanded={expanded === "panel4"}
          onChange={this.handleChange("panel4")}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Custom Range</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <DateTimePicker setDateRange={this.props.setDateRange} />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }
}

export default withStyles(styles)(CustomizedExpansionPanel);
