import "date-fns";
import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import Button from "@material-ui/core/Button";
import {
  MuiPickersUtilsProvider,
  TimePicker,
  DatePicker
} from "material-ui-pickers";

DateFnsUtils.prototype.getStartOfMonth = DateFnsUtils.prototype.startOfMonth;

const styles = {
  grid: {
    // width: "60%"
  },
  root: {
    width: 100
  },
  submitButton: {
    width: "60%",
    margin: "20px auto"
  }
};

class MaterialUIPickers extends React.Component {
  state = {
    // The first commit of Material-UI
    fromDate: moment()
      .startOf("day")
      .format(),
    toDate: moment().format()
    // .format()
  };

  handleFromDateChange = date => {
    console.log(date);

    this.setState({ fromDate: date });
  };

  handleToDateChange = date => {
    console.log(date);

    this.setState({ toDate: date });
  };

  handleSubmit = e => {
    const { fromDate, toDate } = this.state;

    const targetTime = {
      startDate: fromDate,
      endDate: toDate
    };

    if (moment(fromDate).isAfter(toDate)) {
      targetTime.startDate = toDate;
      targetTime.endDate = fromDate;
    }

    this.props.setDateRange(e, targetTime);
  };

  render() {
    const { classes } = this.props;
    const { fromDate, toDate } = this.state;

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid
          container
          className={classes.grid}
          justify="space-around"
          alignItems="center"
        >
          <div>From:</div>
          <DatePicker
            classes={{ root: classes.root }}
            margin="normal"
            // label="Date picker"
            value={fromDate}
            onChange={this.handleFromDateChange}
          />
          <TimePicker
            classes={{ root: classes.root }}
            margin="normal"
            // label="Time picker"
            value={fromDate}
            onChange={this.handleFromDateChange}
          />
        </Grid>
        <Grid
          container
          className={classes.grid}
          justify="space-around"
          alignItems="center"
        >
          <div>&nbsp;To:&nbsp;&nbsp;</div>

          <DatePicker
            classes={{ root: classes.root }}
            margin="normal"
            // label="Date picker"
            value={toDate}
            onChange={this.handleToDateChange}
          />
          <TimePicker
            classes={{ root: classes.root }}
            margin="normal"
            // label="Time picker"
            value={toDate}
            onChange={this.handleToDateChange}
          />
        </Grid>
        <Button
          variant="contained"
          color="primary"
          className={classes.submitButton}
          onClick={this.handleSubmit}
        >
          Submit
        </Button>
      </MuiPickersUtilsProvider>
    );
  }
}

MaterialUIPickers.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(MaterialUIPickers);
