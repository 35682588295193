import CheckCircle from "@material-ui/icons/CheckCircle";
import PlayArrow from "@material-ui/icons/PlayCircleFilledRounded";
import Warning from "@material-ui/icons/WarningRounded";
import Queue from "@material-ui/icons/QueueRounded";
import Cached from "@material-ui/icons/CachedRounded";
import PriorityHigh from "@material-ui/icons/PriorityHighRounded";
import PlaylistAddCheck from "@material-ui/icons/PlaylistAddCheckRounded";
import Inactive from "@material-ui/icons/BlockRounded";
import HourglassEmpty from "@material-ui/icons/HourglassEmpty";

const iconMap = {
  running: PlayArrow,
  failed: Warning,
  success: CheckCircle,
  skipped: PriorityHigh,
  retry: Cached,
  queued: Queue,
  cleared: PlaylistAddCheck,
  null: Inactive,
  CREATED: PlaylistAddCheck,
  CREATE_COMPLETE: CheckCircle,
  init: HourglassEmpty
};

export { iconMap };
