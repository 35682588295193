import React from "react";
import { Formik } from "formik";
import { compose } from "recompose";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import get from "lodash.get";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogActions from "@material-ui/core/DialogActions";
import FormControl from "@material-ui/core/FormControl";
import UserDucker from "../navigation-bar/profile/Profile.ducker";

import { GET_SHARED_PROJECT_USERS_LIST_QUERY } from "./SharedProjectUsersList";
const SHARE_PROJECT = gql`
  mutation ShareProject(
    $projectId: Int!
    $projectOwnerId: Int!
    # $sharedUserId: Int!,
    $email: String!
  ) {
    shareProject(
      projectId: $projectId
      projectOwnerId: $projectOwnerId
      # sharedUserId: $sharedUserId,
      email: $email
    ) {
      statusCode
      error
    }
  }
`;

const errorStyle = theme => ({
  error: {
    color: red[500],
    fontSize: 12
  }
});

function Error({ error, classes }) {
  return <span className={classes.error}>{error}</span>;
}

const ErrorWithStyles = withStyles(errorStyle)(Error);

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  input: {
    display: "flex",
    minWidth: 300,
    width: "100%",
    margin: "20px 0 0 0"
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  buttonsContainer: {
    marginTop: 30
  },
  submitButton: {
    boxShadow: "none"
  }
});

const Basic = ({ mutate, handleClose, classes, id, routeData = {} }) => (
  <Formik
    initialValues={{ email: "" }}
    validate={values => {
      let errors = {};
      if (!values.email) {
        errors.email = "Required";
      }
      return errors;
    }}
    onSubmit={(values, { setSubmitting, setErrors }) => {
      const refetchQueries = [
        {
          query: GET_SHARED_PROJECT_USERS_LIST_QUERY,
          variables: {
            projectId: routeData.id,
            projectOwnerId: id,
            awaitRefetchQueries: true
          }
        }
      ];

      mutate({
        variables: {
          ...values,
          projectId: routeData.id,
          projectOwnerId: id,
          // sharedUserId: 5,
          email: values.email
        },
        refetchQueries
      }).then(res => {
        const statusCode = get(res, "data.shareProject.statusCode");
        const error = get(res, "data.shareProject.error");
        if (res.loading) {
          return;
        }
        if (error) {
          setErrors({ name: "Failed to share the project" });
          setSubmitting(false);
        }
        if (statusCode === 200) {
          setSubmitting(false);
          handleClose();
        }
        console.log(res);
      });
    }}
  >
    {({
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting
      /* and other goodies */
    }) => (
      <form onSubmit={handleSubmit}>
        <FormControl fullWidth>
          <TextField
            className={classes.input}
            type="text"
            label="Email"
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            variant="outlined"
          />
          {errors.email && touched.email && (
            <ErrorWithStyles error={errors.email} />
          )}
          <DialogActions className={classes.buttonsContainer}>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              className={classes.submitButton}
              type="submit"
              disabled={isSubmitting}
              variant="contained"
              color="primary"
            >
              {isSubmitting && <CircularProgress size={24} />}
              {!isSubmitting && "Send"}
            </Button>
          </DialogActions>
        </FormControl>
      </form>
    )}
  </Formik>
);

export default compose(
  withStyles(styles),
  UserDucker.connect,
  graphql(SHARE_PROJECT)
)(Basic);
