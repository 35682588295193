import React from "react";
import { Query } from "react-apollo";
import { PROCESS_CODE_QUERY } from "./Code.graphql.query";
import LinearProgress from "@material-ui/core/LinearProgress";

export default function Launches(props) {
  const { processId, Component } = props;
  return (
    <Query query={PROCESS_CODE_QUERY} variables={{ processId }}>
      {({ loading, error, data }) => {
        if (error) {
          console.log(error);
          return <h1>Error </h1>;
        }
        if (loading) {
          return <LinearProgress style={{ marginTop: 10 }} />;
        }

        const response = data.getProcessCode;

        if (!(response && response.message)) {
          return <h2>There was a problem with the request.</h2>;
        }
        return <Component code={response.message} />;
      }}
    </Query>
  );
}
