import { connect } from "react-redux";
import { addToNamespace } from "../utils/addToNamespace";
import { actionCreators as refreshActionCreators } from "../refresh-button/RefreshButton.ducker";

export const CALENDAR_ACTIONS = {
  SET_DATE: "SET_DATE"
};

export const dateNamespace = "date";

const ACTION_TYPES = addToNamespace(dateNamespace, CALENDAR_ACTIONS);

const actions = {
  setDate: {
    type: ACTION_TYPES.SET_DATE
  }
};

export const actionCreators = {
  setDate: payload => ({ ...payload, ...actions.setDate })
};

export function createDucker() {
  const defaultState = {
    startDate: "",
    endDate: ""
  };

  function reducer(state = defaultState, action) {
    switch (action.type) {
      case ACTION_TYPES.SET_DATE:
        return {
          ...state,
          ...action.payload
        };

      default:
        return { ...state };
    }
  }

  const duck = {
    actionCreators,
    reducer
  };

  duck.connect = function(component) {
    return connect(
      state => {
        return {
          ...state[dateNamespace]
        };
      },
      dispatch => ({
        setDate: payload => {
          dispatch(actionCreators.setDate({ payload }));
        },
        applyDate: () => {
          dispatch(refreshActionCreators.refresh());
        }
      })
    )(component);
  };

  duck.composer = function() {};

  return duck;
}

export default createDucker();
