import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Refresh from "@material-ui/icons/Refresh";
import CircularProgress from "@material-ui/core/CircularProgress";
import classnames from "classnames";
import { compose } from "recompose";
import ducker from "./RefreshButton.ducker";
import { ApolloConsumer } from "react-apollo";
import { PROCESSES_QUERY } from "../process-list/ProcessList";

const styles = {
  section: {
    display: "flex",
    overflowX: "scroll",
    paddingBottom: 15
  },
  button: {
    borderRadius: 0,
    zIndex: 10
  }
};

export const RefreshButton = ({
  classes = {},
  startDate,
  endDate,
  triggerSnackBarOnProcessAction
}) => {
  const [loading, setLoading] = useState(0);

  const Icon = loading ? CircularProgress : Refresh;
  return (
    <ApolloConsumer>
      {client => (
        <Button
          onClick={async () => {
            // const { data } =
            setLoading(1);
            await client.query({
              query: PROCESSES_QUERY,
              variables: {
                startDate,
                endDate
              },
              fetchPolicy: "network-only"
            });
            setLoading(0);
            triggerSnackBarOnProcessAction({
              variant: "info",
              message: "Refetch completed"
            });

            // console.log(client.cache);
            // this.onDogFetched(data.dog);
          }}
          // onClick={() => {
          //   refresh();
          // }}
          className={classnames(classes.button)}
        >
          <Icon size={20} />
        </Button>
      )}
    </ApolloConsumer>
  );
};

export default compose(
  ducker.connect,
  withStyles(styles)
)(RefreshButton);
