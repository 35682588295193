import React from "react";
import { Query } from "react-apollo";
import { PROCESS_LOG_QUERY } from "./Log.graphql.query";
import LinearProgress from "@material-ui/core/LinearProgress";

export default function Launches(props) {
  const {
    processId,
    executionDate,
    parentProcessId,
    tryNumber = 1,
    metadata = "null",
    Component
  } = props;
  return (
    <Query
      query={PROCESS_LOG_QUERY}
      variables={{
        processId,
        executionDate,
        parentProcessId,
        tryNumber,
        metadata
      }}
    >
      {({ loading, error, data }) => {
        if (error) {
          console.log(error);
          return <h1>Error </h1>;
        }
        if (loading) {
          return <LinearProgress style={{ marginTop: 10 }} />;
        }

        const response = data.getProcessLog;

        if (!(response && response.message)) {
          return <h2>There was a problem with the request.</h2>;
        }
        return <Component code={response.message} />;
      }}
    </Query>
  );
}
