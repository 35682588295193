import React, { Component } from "react";
import PropTypes from "prop-types";
import MaterialTable from "material-table";
// import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core";
import { compose } from "recompose";
import gql from "graphql-tag";
import classnames from "classnames";
import "./InstancesList.style.css";
import Button from "@material-ui/core/Button";
import { styles } from "./InstancesList.style";
import { iconMap } from "../utils/iconMap";
import get from "lodash.get";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Redirect } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";

export const GET_NODES_QUERY = gql`
  query getNodes($projectId: String!) {
    getNodes(projectId: $projectId) {
      host
      id
      port
      description
      environment
    }
  }
`;

const stateLabelMap = {
  CREATE_COMPLETE: "running"
};

export const START_INSTANCE_MUTATION = gql`
  mutation startInstance($name: String!) {
    startInstance(name: $name) {
      statusCode
      message
      error
    }
  }
`;

export class InstancesList extends Component {
  state = {
    redirect: false,
    stackName: "",
    nodeId: ""
  };
  columns = [
    {
      title: "State",
      field: "state",
      defaultSort: "asc",
      render: ({ state, healthCheckStatus }) => {
        const { classes } = this.props;
        const Icon = iconMap[state] || iconMap["failed"];
        const iconClassName = classes[state] || classes.failed;
        const stateLabel =
          healthCheckStatus > -1
            ? stateLabelMap[state] || state || "Inactive"
            : "Pending";

        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              textTransform: "capitalize",
              paddingLeft: 20
            }}
          >
            <Icon className={classnames(iconClassName, classes.icon)} />
            {stateLabel}
          </div>
        );
      }
    },
    {
      title: "Name",
      field: "name",
      render: ({ host, port, healthCheckStatus }) => {
        let progress;

        if (healthCheckStatus === 0) {
          progress = (
            <CircularProgress style={{ width: 20, height: 20 }} size="20" />
          );
        }
        console.log(progress);
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ marginRight: 10 }}>{progress}</span>
            <span className={this.props.classes.link}>
              {host}:{port}
            </span>
          </div>
        );
      }
    },
    {
      title: "Status Check",
      field: "healthCheckStatus",
      defaultSort: "asc",
      render: ({ healthCheckStatus }) => {
        const { classes } = this.props;
        let Icon;
        let iconClassName;

        if (healthCheckStatus < 1) {
          return null;
        } else if (healthCheckStatus === 0) {
          Icon = iconMap["init"];
          iconClassName = classes["success"];
        } else {
          Icon = iconMap["success"];
          iconClassName = classes["success"];
        }

        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              textTransform: "capitalize",
              paddingLeft: 20
            }}
          >
            <Icon className={classnames(iconClassName, classes.icon)} />
            <span>"Healthy"</span>
          </div>
        );
      }
    },
    {
      title: "Description",
      field: "description"
    },
    {
      title: "Executor",
      field: "executor"
    },
    {
      title: "Airflow Workers",
      field: "airflowWorkers"
      // defaultSort: "desc"
    },
    {
      title: "Components",
      field: "compTitan",
      render: ({ compTitan, compAstra, compOmni }) => {
        const labels = ["Titan", "Astra", "Omni"];
        return [compTitan, compAstra, compOmni].map((enabled, index) => {
          const color = enabled ? "primary" : null;
          return (
            <Button
              key={index}
              variant="contained"
              color={color}
              size="small"
              style={{ marginRight: 5, boxShadow: "none" }}
            >
              {labels[index]}
            </Button>
          );
        });
      }
    },
    {
      title: "Environment",
      field: "environment"
    }
  ];

  render() {
    const { data, classes, setSelectedInstances, routeData } = this.props;

    console.log(classes);

    const instances = get(data, "getNodes", []);

    if (instances.length === 0) {
      return <p>You do not have any instances in current workspace yet.</p>;
    }

    if (this.state.redirect) {
      const link = `/dashboard/${routeData.id}/${this.state.stackName ||
        this.state.nodeId}`;
      return <Redirect to={link} />;
    }

    return (
      <div className={classes.tableRoot}>
        <MaterialTable
          title=""
          data={instances}
          columns={this.columns}
          components={{
            Toolbar: props => null
          }}
          options={{
            columnsButton: true,
            selection: true,
            pageSize: 11,
            headerStyle: {
              fontWeight: "bold"
            },
            rowStyle: {
              backgroundColor: "#ffffff",
              height: 48,
              "&:nthChild(even)": {
                backgroundColor: "rgba(0, 0, 0, 0.04)"
              }
            }
          }}
          onSelectionChange={payload => {
            console.log("onSelectionChange", payload);
            setSelectedInstances(payload);
            // setSelectedRows({ payload, tab: activeTabLabel });
          }}
          onRowClick={(event, row) => {
            console.log("onRowClick", row);
            // if (row.healthCheckStatus) {
            this.setState({
              redirect: true,
              stackName: row.id,
              nodeId: row.id,
              workspaceId: routeData.id
            });
            // }
          }}
        />
      </div>
    );
  }
}

InstancesList.propTypes = {
  classes: PropTypes.shape({
    link: PropTypes.string
  })
};

InstancesList.defaultProps = {};

const InstancesListWithStyles = withStyles(styles)(InstancesList);

const InstancesQuery = ({ id, routeData = { id }, setSelectedInstances }) => {
  const { loading, error, data } = useQuery(GET_NODES_QUERY, {
    variables: {
      projectId: routeData.id
    }
    // pollInterval: 5000
  });
  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  return (
    <InstancesListWithStyles
      data={data}
      routeData={routeData}
      setSelectedInstances={setSelectedInstances}
    />
  );
};

export default compose(withStyles(styles))(InstancesQuery);
