import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import React, { Component } from "react";
import thunkMiddleware from "redux-thunk";
// import { createLogger } from "redux-logger";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import { Provider } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { ApolloProvider } from "react-apollo";
import { ApolloProvider as HooksApolloProvider } from "@apollo/react-hooks";
import Router from "./components/router/Router";
import tabBarDucker from "./components/tabbar/ducker";
import processStateDucker from "./components/process-state-bar/ducker";
import dagDucker from "./components/process-view/ProcessView.ducker";
import authDucker from "./components/user/auth/Auth.ducker";
import refresh from "./components/refresh-button/RefreshButton.ducker";
import snackbar from "./components/snackbar/Snackbar.ducker";
import datePicker from "./components/date-picker/DatePicker.ducker";
import theme from "./theme";
import createApolloClient from "./createApolloClient";
import "./App.css";
import API_URL from "./constants";

const composeEnhancers =
  window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] || compose;

// const loggerMiddleware = createLogger();

const store = createStore(
  combineReducers({
    tabBar: tabBarDucker.reducer,
    processBar: processStateDucker.reducer,
    process: dagDucker.reducer,
    user: authDucker.reducer,
    refresh: refresh.reducer,
    snackbar: snackbar.reducer,
    date: datePicker.reducer
  }),
  composeEnhancers(
    applyMiddleware(
      thunkMiddleware // lets us dispatch() functions
      // loggerMiddleware // neat middleware that logs actions
    )
  )
);

const client = createApolloClient(`${API_URL}/graphql`);

class App extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <Provider store={store}>
          <ApolloProvider client={client}>
            <HooksApolloProvider client={client}>
              <Router />
            </HooksApolloProvider>
          </ApolloProvider>
        </Provider>
      </MuiThemeProvider>
    );
  }
}

export default App;
