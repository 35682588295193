import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { withStyles } from "@material-ui/core/styles";
import { borderColor } from "../../theme";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import { compose } from "recompose";
import { GET_NODES_QUERY } from "./NodesList";
import { useQuery } from "@apollo/react-hooks";
import { get } from "lodash";

const styles = theme => ({
  root: {
    display: "flex",
    zIndex: 10,
    marginLeft: 15
  },
  paper: {
    marginRight: theme.spacing.unit * 2
  },
  button: {
    padding: 7,
    minWidth: 92,
    borderRadius: 0,
    border: `1px solid ${borderColor}`
  }
});

const SEND_ACTION = gql`
  mutation NodeAction(
    $action: String!
    $instances: [ActionNode]!
    $projectId: String!
  ) {
    nodeAction(action: $action, instances: $instances, projectId: $projectId) {
      statusCode
      message
      error
    }
  }
`;

export const HAS_INSTANCES_QUERY = gql`
  query getNodes($projectId: String!) {
    getNodes(projectId: $projectId) {
      id
    }
  }
`;

function MenuListComposition(props) {
  let anchorEl;
  const [state, setState] = useState({ open: false, selected: false });
  const { data, loading, error } = useQuery(HAS_INSTANCES_QUERY, {
    variables: {
      projectId: props.routeData && props.routeData.id
    }
  });

  const instancesLength = get(data, "getNodes.length");
  console.log("instancesLength", instancesLength);
  if (loading || error || !instancesLength || instancesLength < 1) {
    return null;
  }

  function handleToggle() {
    setState({ ...state, open: !state.open });
  }

  function handleItemClick(event, { action }) {
    setState({ ...state, open: false });
    handleClose(event);

    const {
      mutate,
      // triggerSnackBarOnProcessAction,
      routeData,
      selectedInstances
    } = props;

    const projectId = routeData.id;

    const refetchQueries = [
      {
        query: GET_NODES_QUERY,
        variables: {
          fetchPolicy: "network-only",
          awaitRefetchQueries: true,
          projectId
        }
      }
    ];

    if (selectedInstances.length) {
      mutate({
        variables: {
          action,
          instances: selectedInstances.map(instance => ({
            id: instance.id
          })),
          projectId
        },
        refetchQueries,
        onCompleted: data => {
          console.log("InstanceActionDropdown onComplete:", data);
        }
      })
        .then(res => {
          if (res.loading) {
            return;
          }

          console.log(res);
          // triggerSnackBarOnProcessAction({
          //   variant: "success",
          //   message: "Action successful"
          // });
        })
        .catch(e => {
          console.log(e);
        });
    }
  }

  function handleClose(event) {
    if (anchorEl.contains(event.target)) {
      return;
    }
    setState({ ...state, open: false });
  }

  const { classes, selectedInstances } = props;
  const { open, selected } = state;

  console.log("selectedInstances", selectedInstances);

  const InstanceActionList = (
    <React.Fragment>
      {/* <MenuItem
        disabled={selectedInstances.length === 0}
        onClick={e => {
          handleItemClick(e, {
            action: "start"
          });
        }}
      >
        Launch
      </MenuItem> */}

      <MenuItem
        disabled={selectedInstances.length === 0}
        onClick={e => {
          handleItemClick(e, {
            action: "delete"
          });
        }}
      >
        Delete
      </MenuItem>
    </React.Fragment>
  );

  return (
    <div className={classes.root}>
      <div>
        <Button
          className={classes.button}
          buttonRef={node => {
            anchorEl = node;
          }}
          aria-owns={open ? "menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          {selected || "Action"} {open ? <ArrowDropUp /> : <ArrowDropDown />}
        </Button>
        <Popper
          open={open}
          anchorEl={anchorEl}
          style={{ zIndex: 15, position: "absolute" }}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList>{InstanceActionList}</MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}

MenuListComposition.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default compose(
  withStyles(styles),
  graphql(SEND_ACTION)
)(MenuListComposition);
