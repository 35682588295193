import gql from "graphql-tag";

export const PROCESS_DETAILS_QUERY = gql`
  query processDetails(
    $processId: String!
    $executionDate: String!
    $typeProcess: String!
    $parentProcessId: String
  ) {
    processDetails(
      processId: $processId
      executionDate: $executionDate
      typeProcess: $typeProcess
      parentProcessId: $parentProcessId
    ) {
      processId
      endDate
      isPaused
      progress {
        value
        success
        total
      }
      runInfo {
        id
        date
      }
      processSchedule {
        tasksTotal
        scheduleInterval
        maxActiveRuns
        concurrency
        owner
        filePath
        taskIds
        defaultArgs {
          start_date
          depends_on_past
          owner
        }
        maxActiveRuns
      }
      state
      startDate
      upstreamTasks
      downstreamTasks
    }
  }
`;
