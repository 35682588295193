import { connect } from "react-redux";

const namespace = "userProfile";

export function createDucker({ namespace }) {
  const duck = {};

  duck.connect = function(component) {
    return connect(state => {
      return {
        ...state.user
      };
    })(component);
  };

  return duck;
}

export default createDucker({ namespace });
