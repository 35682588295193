import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import classnames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const styles = {
  root: {
    paddingLeft: 16,
    paddingRight: 16,
    color: "#c9c9c9",
    "&:hover": {
      backgroundColor: "#fdf8f814"
    }
  },
  isSelected: {
    backgroundColor: "#383737de"
  },
  highlightBar: {
    position: "absolute",
    top: 0,
    height: "100%",
    width: 4,
    backgroundColor: "#3d7ec2",
    zIndex: 1
  },
  isHighlighted: {
    backgroundColor: "#383737de"
  },
  link: {
    position: "relative",
    display: "flex",
    textDecoration: "none"
  },
  linkLabel: {
    opacity: 0,
    transition: "0.5s",
    display: "flex",
    alignItems: "center",
    "& span": {
      color: "#bdbbbb",
      fontSize: "12px",
      whiteSpace: "nowrap",
      paddingLeft: "10px"
    }
  },
  visibleLinkLabel: {
    opacity: 1
  }
};

function MenuLink({
  navigationLink,
  navigationLabel,
  root = "",
  selected = false,
  classes,
  routerSelected = null,
  Icon = null,
  className = "",
  isExpanded
}) {
  const isHighlighted =
    routerSelected &&
    routerSelected[0] &&
    routerSelected[0] === navigationLabel;

  return (
    <Link
      to={navigationLink}
      className={classnames(classes.root, classes.link, className)}
    >
      {isHighlighted && <span className={classes.highlightBar} />}
      <ListItem
        className={classnames(root, {
          [classes.isSelected]: selected,
          [classes.isHighlighted]: isHighlighted
        })}
        button
      >
        {Icon && <Icon />}

        <ListItemText
          className={classnames(
            classes.linkLabel,
            isExpanded && classes.visibleLinkLabel
          )}
          primary={navigationLabel}
        />
      </ListItem>
    </Link>
  );
}

export default withStyles(styles)(MenuLink);
