import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import NavLink from "../navLink/NavLink";
import ExpandRight from "@material-ui/icons/ChevronRight";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import classnames from "classnames";

const styles = {
  root: {
    paddingTop: 0,
    paddingBottom: 0,
    position: "relative",
    color: "#bdbbbb"
    // "&:hover": {
    //   backgroundColor: "#fdf8f814"
    // }
  },
  submenuSelected: {
    backgroundColor: "#fdf8f814"
  },
  highlightBar: {
    position: "absolute",
    top: 0,
    height: "100%",
    width: 4,
    backgroundColor: "#3d7ec2",
    zIndex: 1
  },
  link: {
    paddingLeft: 50

    // "&:hover": {
    //   backgroundColor: "#5e5b5b36"
    // }
  },
  listItemRoot: {
    paddingLeft: 0,
    paddingRight: 0
  },
  isCollapsedIcon: {
    height: 48
  },
  linkLabel: {
    opacity: 0,
    transition: "0.5s",
    display: "flex",
    alignItems: "center",
    "& span": {
      color: "#bdbbbb",
      fontSize: "12px",
      whiteSpace: "nowrap",
      paddingLeft: "10px"
    }
  },
  visibleLinkLabel: {
    opacity: 1
  }
};

class SubMenu extends React.Component {
  render() {
    const {
      classes,
      navigationLabel,
      navigationLinks,
      onMenuClick,
      selected,
      routerSelected = [],
      Icon = null,
      isExpanded
    } = this.props;
    const sideList = (
      <Collapse
        in={isExpanded && selected[0] === navigationLabel}
        className={classes.collapse}
        timeout="auto"
      >
        <div className={classes.list}>
          {navigationLinks.map(({ navigationLabel, navigationLink }, index) => (
            <NavLink
              isExpanded={isExpanded}
              className={classes.listItemRoot}
              selected={selected[1] === navigationLabel}
              key={index}
              root={classes.link}
              navigationLink={navigationLink}
              navigationLabel={navigationLabel}
            />
          ))}
        </div>
      </Collapse>
    );

    const isSelected =
      selected && selected.length ? navigationLabel === selected[0] : false;
    const menuIcon = isSelected ? <ExpandMore /> : <ExpandRight />;
    const shouldHightlight =
      routerSelected && routerSelected.length
        ? navigationLabel === routerSelected[0]
        : false;

    return (
      <List
        component="nav"
        className={classnames(
          {
            [classes.submenuSelected]: isSelected
          },
          classes.root
        )}
      >
        {shouldHightlight && <div className={classes.highlightBar} />}

        <ListItem
          className={classnames({ [classes.isCollapsedIcon]: !isExpanded })}
          button
          onClick={() => {
            onMenuClick(navigationLabel, isSelected);
          }}
        >
          {Icon && <Icon />}{" "}
          <ListItemText
            className={classnames(
              classes.linkLabel,
              isExpanded && classes.visibleLinkLabel
            )}
            primary={navigationLabel}
          />
          {menuIcon}
        </ListItem>

        {sideList}
      </List>
    );
  }
}

SubMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool
};

export default withStyles(styles)(SubMenu);
