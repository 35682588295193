import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Close from "@material-ui/icons/Close";
import classnames from "classnames";

const styles = {
  section: {
    display: "flex",
    overflowX: "scroll",
    paddingBottom: 15
  },
  button: {
    borderRadius: 0,
    border: "none", //"1px solid #e4e0e0",
    backgroundColor: "#00000014",
    paddingRight: 10,
    whiteSpace: "nowrap"
  },
  isActive: {
    borderBottom: "1px solid white",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "white"
    }
  },
  tabUnderline: {
    width: "100%",
    display: "flex"
    // borderBottom: "1px solid #e4e0e0"
  },
  tabUnderlineChip: {
    width: 20,
    display: "flex"
    // borderBottom: "1px solid #e4e0e0"
  },
  noTabUnderline: {
    display: "flex"
  },
  rightIcon: {
    marginLeft: 5,
    fontSize: 15
  }
};

const TabBar = ({
  classes = {},
  tabs,
  onCloseClick,
  onTabClick,
  activeTabLabel
}) => {
  // console.log('onTabClick', onTabClick)
  return (
    <section className={classes.section}>
      <span className={classes.tabUnderlineChip}>&nbsp;</span>
      <span className={classes.noTabUnderline}>
        {tabs.map((tab, ii) => {
          const isActive = tab.label === activeTabLabel;
          return (
            <Button
              key={ii}
              onClick={onTabClick}
              data-tab-label={tab.label}
              data-process-id={tab.processId}
              data-execution-date={tab.executionDate}
              className={classnames(classes.button, {
                [classes.isActive]: isActive
              })}
            >
              {tab.label}
              {!tab.isPermanent && (
                <Close
                  onClick={onCloseClick}
                  data-tab-label={tab.label}
                  className={classes.rightIcon}
                />
              )}
            </Button>
          );
        })}
      </span>
      <span className={classes.tabUnderline}>&nbsp;</span>
    </section>
  );
};

export default withStyles(styles)(TabBar);
