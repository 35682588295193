export const styles = {
  link: {
    textDecoration: "none",
    color: "#36b8d8",
    textTransform: "uppercase",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline"
    }
  },
  success: {
    color: "#6ec700"
  },
  failed: {
    color: "#ff0000"
  },
  running: {
    color: "#ffa500"
  },
  init: {
    color: "#ffa500"
  },
  queued: {
    color: "#ff0000"
  },
  null: {
    color: "#c9c9c9"
  },
  icon: {
    marginRight: 5,
    fontSize: 18
  },
  tableRoot: {
    margin: "0 10px",
    "&>div": {
      boxShadow: "none"
    }
  }
};
