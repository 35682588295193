import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import green from "@material-ui/core/colors/green";

const styles = {
  root: {
    // color: green[600],
    "&$checked": {
      color: green[500]
    }
  },
  checked: {}
};

class Checkboxes extends React.Component {
  state = {
    upstream: false,
    downstream: false,
    failed: false
  };

  handleChange = name => event => {
    event.stopPropagation();

    switch (name) {
      case "upstream": {
        this.setState({ upstream: event.target.checked, failed: false });
        break;
      }
      case "downstream": {
        this.setState({ downstream: event.target.checked, failed: false });
        break;
      }
      case "failed": {
        this.setState({
          upstream: false,
          downstream: false,
          failed: event.target.checked
        });
        break;
      }
      default:
        break;
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <DialogContent>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.upstream}
                  onChange={this.handleChange("upstream")}
                  value="upstream"
                  classes={{
                    root: classes.root,
                    checked: classes.checked
                  }}
                />
              }
              label="Restart `Upstream`"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.downstream}
                  onChange={this.handleChange("downstream")}
                  value="downstream"
                  color="primary"
                />
              }
              label="Restart `Downstream`"
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.failed}
                  onChange={this.handleChange("failed")}
                  value="failed"
                />
              }
              label="Restart `Failed`"
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={e => {
              this.props.handleRestartClose(e, { ...this.state });
            }}
            color="primary"
            autoFocus
          >
            Restart
          </Button>
        </DialogActions>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Checkboxes);
