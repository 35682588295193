import React from "react";
import { withStyles } from "@material-ui/core";

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "2em",
    textAlign: "center"
  }
};

export function Confirmed({ classes }) {
  return (
    <section className={classes.root}>
      <h4>It's a Success!</h4>
      <p>
        Your account is fully verified. Please{" "}
        <a href="/login">click here to Login</a>
      </p>
    </section>
  );
}

export default withStyles(styles)(Confirmed);
