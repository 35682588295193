import { connect } from "react-redux";
const namespace = "user";

export const USER_ACTIONS = {
  SET_ACTIVE_USER: "SET_ACTIVE_USER"
};
function createDucker({ namespace }) {
  const actions = {
    SET_ACTIVE_USER: {
      type: USER_ACTIONS.SET_ACTIVE_USER
    }
  };

  const actionCreators = {
    setActiveUser: payload => ({ ...payload, ...actions.SET_ACTIVE_USER })
  };
  const defaultState = {
    firstName: "",
    lastName: "",
    email: "",
    avatar: ""
  };

  const selectors = state => ({
    user: state => state.user
  });

  function reducer(state = defaultState, action) {
    switch (action.type) {
      case USER_ACTIONS.SET_ACTIVE_USER:
        console.log("in reducer", action.paylod);
        return { ...state, ...action.payload };
      default:
        return { ...state };
    }
  }

  const duck = {
    actionCreators,
    reducer,
    selectors
  };

  duck.connect = function(component) {
    return connect(
      state => ({
        user: state.user
      }),
      dispatch => ({
        setActiveUser: user => {
          console.log("setActiveUser action", user);
          dispatch(actionCreators.setActiveUser({ payload: user }));
        }
      })
    )(component);
  };

  duck.composer = function() {};
  return duck;
}

const AuthDucker = createDucker({ namespace });

export default AuthDucker;
