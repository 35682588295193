import React from "react";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import classnames from "classnames";
import PercentBar from "../percent-bar/PercentBar";
import { processListStyles } from "../process-list/ProcessList.style";
import { iconMap } from "../utils/iconMap";
import { PROCESS_DETAILS_QUERY } from "./Info.graphql.query";

const styles = {
  sectionContainer: {
    display: "flex",
    alignItems: "center",
    margin: "5px 0"
  },
  multiLineSectionContainer: {
    display: "flex",
    margin: "5px 0"
  },
  sectionTitle: {
    fontWeight: "bold"
  },
  sectionLabel: {
    width: 70,
    textAlign: "end",
    marginRight: 10
  },
  sectionLabelForDetails: {
    width: 100
  },
  row: {
    width: 400
  },
  detailsRow: {
    width: 300
  },
  ...processListStyles
};

const SEND_PAUSE_UNPAUSE_ACTION = gql`
  mutation togglePausedStateOnProcess(
    $processId: String!
    $isPaused: Boolean!
  ) {
    togglePausedStateOnProcess(processId: $processId, isPaused: $isPaused) {
      statusCode
      message
    }
  }
`;

function sendMutation({ isPaused, mutate, processId, parentData }) {
  mutate({
    variables: {
      action: "togglePausedStateOnProcess",
      processId,
      isPaused
    },
    refetchQueries: [
      {
        query: PROCESS_DETAILS_QUERY,
        variables: {
          ...parentData,
          awaitRefetchQueries: true
        }
      }
    ]
  }).then(res => {
    console.log(res);
  });
}

function GeneralInfo(props) {
  console.log("GeneralInfo", props);
  const {
    processId,
    state,
    startDate,
    endDate,
    progress,
    runInfo,
    processSchedule,
    classes,
    mutate,
    parentData,
    isPaused,
    upstreamTasks,
    downstreamTasks
  } = props;

  const {
    tasksTotal,
    scheduleInterval,
    maxActiveRuns,
    concurrency,
    defaultArgs,
    taskIds,
    filePath,
    owner
  } = processSchedule;

  const Icon = iconMap[state] || iconMap["failed"];
  const iconClassName = classes[state] || classes.failed;

  // const [isProcessPaused, updateSwitch] = useState(isPaused);
  const typeLabel =
    (parentData &&
      parentData.typeProcess &&
      parentData.typeProcess.toUpperCase()) ||
    "DAG";
  return (
    <React.Fragment>
      <Typography gutterBottom variant="h5" component="h3">
        {typeLabel}: {processId}
      </Typography>
      <Divider />
      {typeLabel === "DAG" && (
        <div className={classes.sectionContainer}>
          <span className={classes.sectionLabel}>
            <Switch
              checked={!isPaused}
              onChange={() => {
                sendMutation({
                  isPaused,
                  processId,
                  mutate,
                  parentData
                });
                // updateSwitch(!isProcessPaused);
              }}
              value="checkedA"
              color="primary"
            />
          </span>
          <Typography component="p" className={classes.row}>
            Use this toggle to pause a DAG. The scheduler won't schedule new
            tasks instances for a paused DAG. Tasks already running at pause
            time won't be affected.
          </Typography>
        </div>
      )}
      <Divider />
      <div className={classes.sectionContainer}>
        <Typography
          component="span"
          variant="subtitle2"
          className={classnames(classes.sectionLabel, classes.sectionTitle)}
        >
          Status:
        </Typography>
        <Typography
          component="div"
          className={classnames(classes.row, classes.sectionContainer)}
        >
          {Icon && <Icon className={classnames(iconClassName, classes.icon)} />}
          <span>{state}</span>
        </Typography>
      </div>
      <Divider />
      <div className={classes.sectionContainer}>
        <span className={classes.sectionLabel}>Start:</span>
        <Typography
          component="p"
          className={classnames(classes.row, classes.sectionContainer)}
        >
          <span>{startDate}</span>
        </Typography>
      </div>
      <div className={classes.sectionContainer}>
        <span className={classes.sectionLabel}>End:</span>
        <Typography
          component="p"
          className={classnames(classes.row, classes.sectionContainer)}
        >
          <span>{endDate}</span>
        </Typography>
      </div>
      <div className={classes.sectionContainer}>
        <span className={classes.sectionLabel}>Progress:</span>
        <Typography
          component="p"
          className={classnames(classes.sectionContainer)}
        >
          <PercentBar
            tasksCompleted={progress.success}
            totalTasks={progress.total}
            barWidth={200}
          />
        </Typography>
      </div>
      <Divider />
      <div className={classes.sectionContainer}>
        <span
          className={classnames(classes.sectionLabel, classes.sectionTitle)}
        >
          Run Info:
        </span>
      </div>
      <div className={classes.sectionContainer}>
        <span className={classes.sectionLabel}>ID:</span>
        <Typography
          component="p"
          className={classnames(classes.sectionContainer)}
        >
          {runInfo.id}
        </Typography>
      </div>

      <div className={classes.sectionContainer}>
        <span className={classes.sectionLabel}>Date:</span>
        <Typography
          component="p"
          className={classnames(classes.sectionContainer)}
        >
          {runInfo.date}
        </Typography>
      </div>
      <Divider />

      <div className={classes.sectionContainer}>
        <span
          className={classnames(
            classes.sectionLabelForDetails,
            classes.sectionTitle
          )}
        >
          Details:
        </span>
      </div>
      <div className={classes.sectionContainer}>
        <span className={classes.sectionLabelForDetails}>Tasks Count:</span>
        <Typography
          component="p"
          className={classnames(classes.sectionContainer)}
        >
          {tasksTotal}
        </Typography>
      </div>

      {upstreamTasks && (
        <div className={classes.sectionContainer}>
          <span className={classes.sectionLabelForDetails}>
            Upstream tasks:
          </span>
          <Typography
            component="p"
            className={classnames(classes.sectionContainer)}
          >
            {upstreamTasks.join(",")}
          </Typography>
        </div>
      )}

      {downstreamTasks && (
        <div className={classes.sectionContainer}>
          <span className={classes.sectionLabelForDetails}>
            Downstream tasks:
          </span>
          <Typography
            component="p"
            className={classnames(classes.sectionContainer)}
          >
            {downstreamTasks.join(",")}
          </Typography>
        </div>
      )}

      <div className={classes.sectionContainer}>
        <span className={classes.sectionLabelForDetails}>
          Schedule Interval:
        </span>
        <Typography
          component="p"
          className={classnames(classes.sectionContainer)}
        >
          {scheduleInterval}
        </Typography>
      </div>

      <div className={classes.sectionContainer}>
        <span className={classes.sectionLabelForDetails}>Max Active Runs:</span>
        <Typography
          component="p"
          className={classnames(classes.sectionContainer)}
        >
          {maxActiveRuns}
        </Typography>
      </div>

      <div className={classes.sectionContainer}>
        <span className={classes.sectionLabelForDetails}>Concurrency:</span>
        <Typography
          component="p"
          className={classnames(classes.sectionContainer)}
        >
          {concurrency}
        </Typography>
      </div>

      <div className={classes.multiLineSectionContainer}>
        <span className={classnames(classes.sectionLabelForDetails)}>
          Default Args:
        </span>
        <section>
          <Typography
            component="p"
            className={classnames(classes.sectionContainer)}
          >
            Start date: {defaultArgs.start_date}
          </Typography>
          <Typography
            component="p"
            className={classnames(classes.sectionContainer)}
          >
            Depends on last: {defaultArgs.depends_on_past}
          </Typography>
          <Typography
            component="p"
            className={classnames(classes.sectionContainer)}
          >
            Owner: {defaultArgs.owner}
          </Typography>
        </section>
      </div>
      <div className={classes.sectionContainer}>
        <span className={classes.sectionLabelForDetails}>Task ID's:</span>
        <Typography
          component="p"
          className={classnames(classes.sectionContainer)}
        >
          {taskIds.map(id => id).join(", ")}
        </Typography>
      </div>
      <Divider />
      <div className={classes.sectionContainer}>
        <span className={classes.sectionLabelForDetails}>Filepath:</span>
        <Typography
          component="p"
          className={classnames(classes.sectionContainer)}
        >
          {filePath}
        </Typography>
      </div>
      <Divider />
      <div className={classes.sectionContainer}>
        <span className={classes.sectionLabelForDetails}>Owner:</span>
        <Typography
          component="p"
          className={classnames(classes.sectionContainer)}
        >
          {owner}
        </Typography>
      </div>
      <Divider />
    </React.Fragment>
  );
}

export default compose(
  withStyles(styles),
  graphql(SEND_PAUSE_UNPAUSE_ACTION)
)(GeneralInfo);
