import invariant from "invariant";

/**
 *
 * @param {string} namespace
 * @param {object} actions
 * @returns {object} object with each action value being namespaced `namespace.SOME_ACTION`
 */

export function addToNamespace(namespace, actions) {
  invariant(
    namespace && typeof namespace === "string",
    `namespace provided to addToNamespace call must be a string, received ${namespace}`
  );
  invariant(
    actions && typeof actions === "object",
    `actions provided to addToNamespace call must be an object, received ${actions}`
  );

  return Object.keys(actions).reduce((prev, curr) => {
    prev[curr] = `${namespace}.${actions[curr]}`;
    return prev;
  }, {});
}
