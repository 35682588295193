import { connect } from "react-redux";
import { actionCreators } from "../snackbar/Snackbar.ducker";

const namespace = "process";

export function createDucker({ namespace }) {
  return {
    connect: function(component) {
      return connect(
        state => {
          return {
            ...state[namespace],
            isMainView: state.tabBar.activeTab.label === "MAIN",
            selectedTabLabel: state.tabBar.activeTab.label,
            parentProcessId: state.tabBar.activeTab.processId,
            executionDate: state.tabBar.activeTab.executionDate,
            startDate: state.date.startDate,
            endDate: state.date.endDate
          };
        },
        dispatch => ({
          triggerSnackBarOnProcessAction: payload =>
            dispatch(actionCreators.showSnackBar({ payload }))
        })
      )(component);
    }
  };
}

// connect dropdown to `process` state
// so we can access selected rows and pass those to actions

export default createDucker({ namespace });
