import React from "react";
import { Formik } from "formik";
import { compose } from "recompose";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import get from "lodash.get";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogActions from "@material-ui/core/DialogActions";
import FormControl from "@material-ui/core/FormControl";
import AuthDucker from "../user/auth/Auth.ducker";

import { GET_PROJECTS_QUERY } from "./Workspace";
const ADD_PROJECT = gql`
  mutation AddProject($userId: String!, $name: String!, $description: String!) {
    addProject(userId: $userId, name: $name, description: $description) {
      statusCode
      error
    }
  }
`;

const errorStyle = theme => ({
  error: {
    color: red[500],
    fontSize: 12
  }
});

function Error({ error, classes }) {
  return <span className={classes.error}>{error}</span>;
}

const ErrorWithStyles = withStyles(errorStyle)(Error);

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  input: {
    display: "flex",
    minWidth: 300,
    width: "100%",
    margin: "20px 0 0 0"
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  buttonsContainer: {
    marginTop: 30
  },
  submitButton: {
    boxShadow: "none"
  }
});

const Basic = ({ mutate, handleClose, classes, user }) => (
  <Formik
    initialValues={{ name: "", description: "" }}
    validate={values => {
      let errors = {};
      if (!values.name) {
        errors.name = "Required";
      } else if (!values.description) {
        errors.description = "Invalid description";
      }
      return errors;
    }}
    onSubmit={(values, { setSubmitting, setErrors }) => {
      console.log("in create project", user);
      const refetchQueries = [
        {
          query: GET_PROJECTS_QUERY,
          variables: {
            userId: user.id,
            awaitRefetchQueries: true
          }
        }
      ];
      mutate({
        variables: {
          ...values,
          userId: user.id
        },
        refetchQueries
      }).then(res => {
        const statusCode = get(res, "data.addProject.statusCode");
        const error = get(res, "data.addProject.error");
        if (res.loading) {
          return;
        }
        if (error) {
          setErrors({ name: "Projct with such name already exists" });
          setSubmitting(false);
        }
        if (statusCode === 200) {
          setSubmitting(false);
          handleClose();
        }
        console.log(res);
      });
    }}
  >
    {({
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting
      /* and other goodies */
    }) => (
      <form onSubmit={handleSubmit}>
        <FormControl fullWidth>
          <TextField
            className={classes.input}
            type="text"
            label="Name"
            name="name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            variant="outlined"
          />
          {errors.name && touched.name && (
            <ErrorWithStyles error={errors.name} />
          )}
          <TextField
            className={classes.input}
            classes={{ root: classes.input }}
            type="text"
            label="Description"
            name="description"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.description}
            variant="outlined"
          />
          {errors.description && touched.description && (
            <ErrorWithStyles error={errors.description} />
          )}
          {/* <button type="submit" disabled={isSubmitting}>
            Create
          </button> */}
          <DialogActions className={classes.buttonsContainer}>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              className={classes.submitButton}
              type="submit"
              disabled={isSubmitting}
              variant="contained"
              color="primary"
            >
              {isSubmitting && <CircularProgress size={24} />}
              {!isSubmitting && "Save"}
            </Button>
          </DialogActions>
        </FormControl>
      </form>
    )}
  </Formik>
);

export default compose(
  withStyles(styles),
  AuthDucker.connect,
  graphql(ADD_PROJECT)
)(Basic);
