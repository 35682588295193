export const namespace = "TabBar";
export const TAB_BAR_ACTIONS = {
  ADD: "ADD_TAB",
  REMOVE: "REMOVE_TAB",
  SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
  TASK_DETAILS_SHOW: "TASK_DETAILS_SHOW",
  TASK_DETAILS_HIDE: "TASK_DETAILS_HIDE",
  FETCH_BY_PROCESS_STATUS: "FETCH_BY_PROCESS_STATUS",
  FETCH_BY_ALL_PROCESS_STATUS: "FETCH_BY_ALL_PROCESS_STATUS"
};
