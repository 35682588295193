import { connect } from "react-redux";

const namespace = "processDetails";

export function createDucker({ namespace }) {
  const duck = {};

  duck.connect = function(component) {
    return connect(state => {
      console.log("state", state);
      return {
        ...state,
        processId: state.process.processId,
        executionDate: state.process.executionDate,
        parentProcessId: state.tabBar.activeTab.processId,
        typeProcess: state.process.typeProcess
      };
    })(component);
  };

  return duck;
}

export default createDucker({ namespace });
