import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import ProfileDucker from "./Profile.ducker";
import Avatar from "@material-ui/core/Avatar";
import deepOrange from "@material-ui/core/colors/deepOrange";
import deepPurple from "@material-ui/core/colors/deepPurple";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import { Redirect } from "react-router";

const styles = {
  root: {
    flexGrow: 1,
    display: "flex",
    width: "100%",
    top: 0,
    left: 0,
    justifyContent: "flex-end"
  },
  appBar: {
    // backgroundColor: "#ffffff"
  },
  avatar: {
    margin: 10
  },
  orangeAvatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: deepOrange[500]
  },
  purpleAvatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: deepPurple[500]
  },
  paper: {
    top: 49
  }
};

class Profile extends Component {
  state = {
    anchorEl: null,
    redirect: null,
    redirectRoute: null
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  logOut = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/logout`,
        { email: this.props.email },
        {
          withCredentials: true
        }
      );
      if (response && response.data && response.data.redirect) {
        this.redirectTo("/login");
      }
      console.log("logout", response);
    } catch (err) {
      console.log("registerUser error", err);
    }
  };

  redirectTo = redirectRoute => {
    redirectRoute &&
      this.setState({
        redirect: true,
        redirectRoute: redirectRoute
      });
  };

  render() {
    const { classes, firstName = "", lastName = "" } = this.props;
    const firstN = firstName.charAt(0);
    const lastN = lastName.charAt(0);
    const { anchorEl, redirect, redirectRoute } = this.state;

    if (redirect && redirectRoute) {
      return <Redirect to={redirectRoute} />;
    }

    return (
      <div className={classes.root}>
        <div>
          <Avatar
            className={classes.purpleAvatar}
            aria-owns={anchorEl ? "nav-profile-menu" : undefined}
            aria-haspopup="true"
            onClick={this.handleClick}
          >
            {firstN}
            {lastN}
          </Avatar>

          <Menu
            id="nav-profile-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
            className={classes.paper}
          >
            <MenuItem onClick={this.handleClose}>Profile</MenuItem>
            <MenuItem onClick={this.handleClose}>My account</MenuItem>
            <MenuItem
              onClick={() => {
                this.logOut();
                this.handleClose();
              }}
            >
              Logout
            </MenuItem>
          </Menu>
        </div>
      </div>
    );
  }
}

Profile.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withStyles(styles),
  ProfileDucker.connect
)(Profile);
