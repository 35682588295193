import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DatePanel from "./DatePanel";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import ducker from "./DateDropdown.ducker";

const styles = {
  root: {
    flexGrow: 1,
    display: "flex",
    width: "100%",
    top: 0,
    left: 0,
    justifyContent: "flex-end"
  },

  buttonLabel: {
    borderRadius: 0,
    border: "1px solid #eeeeee"
  },

  paper: {
    top: 49
  },
  singleItem: {
    backgroundColor: "#f7f7f7",
    borderBottom: "1px solid #00000020",
    height: 34
  },
  singleItemLast: {
    backgroundColor: "#f7f7f7",
    height: 34,
    borderBottom: "none"
  },
  menuRoot: {
    padding: 0
  }
};

function getDatesForTimeframe(timeframe) {
  if (!timeframe) {
    return { startDate: null, endDate: null };
  }
  switch (timeframe) {
    case "today":
      return {
        startDate: moment()
          .startOf("day")
          .format(),
        endDate: moment().format()
      };
    case "yesterday":
      return {
        startDate: moment()
          .subtract(1, "days")
          .startOf("day")
          .format(),
        endDate: moment()
          .subtract(1, "days")
          .endOf("day")
          .format()
      };
    case "thisWeek":
      return {
        startDate: moment()
          .startOf("week")
          .format(),
        endDate: moment().format()
      };
    case "thisMonth":
      return {
        startDate: moment()
          .startOf("month")
          .format(),
        endDate: moment().format()
      };
    case "thisQuater":
      return {
        startDate: moment()
          .quarter(moment().quarter())
          .startOf("quarter")
          .format(),

        endDate: moment().format()
      };
    case "thisYear":
      return {
        startDate: moment()
          .startOf("year")
          .format(),

        endDate: moment().format()
      };
    case "lastWeek":
      return {
        startDate: moment()
          .startOf("week")
          .subtract(1, "week")
          .format(),
        endDate: moment()
          .startOf("week")
          .subtract(1, "seconds")
          .format()
      };
    case "lastMonth":
      return {
        startDate: moment()
          .startOf("month")
          .subtract(1, "months")
          .format(),
        endDate: moment()
          .startOf("month")
          .subtract(1, "second")
          .format()
      };
    case "lastQuater":
      return {
        startDate: moment()
          .quarter(moment().quarter())
          .subtract(3, "months")
          .startOf("quarter")
          .format(),

        endDate: moment()
          .quarter(moment().quarter())
          .startOf("quarter")
          .subtract(1, "seconds")
          .format()
      };
    case "lastYear":
      return {
        startDate: moment()
          .startOf("year")
          .subtract(1, "year")
          .format(),

        endDate: moment()
          .startOf("year")
          .subtract(1, "second")
          .format()
      };

    case "last7Days":
      return {
        startDate: moment()
          .subtract(7, "days")
          .startOf("day")
          .format(),

        endDate: moment().format()
      };

    case "last14Days":
      return {
        startDate: moment()
          .subtract(14, "days")
          .startOf("day")
          .format(),

        endDate: moment().format()
      };

    case "last30Days":
      return {
        startDate: moment()
          .subtract(30, "days")
          .startOf("day")
          .format(),

        endDate: moment().format()
      };

    case "last60Days":
      return {
        startDate: moment()
          .subtract(60, "days")
          .startOf("day")
          .format(),

        endDate: moment().format()
      };

    case "last120Days":
      return {
        startDate: moment()
          .subtract(120, "days")
          .startOf("day")
          .format(),

        endDate: moment().format()
      };

    default:
      return {
        startDate: moment()
          .startOf("day")
          .format(),
        endDate: moment().format()
      };
  }
}

class DateDropdown extends Component {
  state = {
    anchorEl: null,
    redirect: null,
    redirectRoute: null,
    label: "All Time"
  };

  setDate = event => {
    console.log("in setDate", event);
    const targetTime = event.currentTarget.getAttribute("targettime");

    const { startDate, endDate } = getDatesForTimeframe(targetTime);
    this.props.setDate({ startDate, endDate });
    this.setState({
      anchorEl: event.currentTarget,
      label: event.currentTarget.innerText
    });
    this.handleClose();
  };

  handleClick = event => {
    console.log("in handleClick", event);
    this.setState({ anchorEl: event.currentTarget });
  };

  setDateRange = (event, targetTime) => {
    const { startDate, endDate } = targetTime;
    this.props.setDate({ startDate, endDate });
    this.setState({
      anchorEl: event.currentTarget,
      label: "Custom Range"
    });
    this.handleClose();
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes } = this.props;
    const { anchorEl, label } = this.state;

    return (
      <div className={classes.root}>
        <div>
          <Button
            className={classes.buttonLabel}
            aria-owns={anchorEl ? "nav-profile-menu" : undefined}
            aria-haspopup="true"
            onClick={this.handleClick}
          >
            <Typography>{label}</Typography>
          </Button>
          <Menu
            id="nav-profile-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
            className={classes.paper}
            classes={{ root: classes.menuRoot }}
          >
            <MenuItem
              classes={{ root: classes.singleItem }}
              onClick={this.setDate}
            >
              <Typography>All Time</Typography>
            </MenuItem>
            <MenuItem
              classes={{ root: classes.singleItem }}
              onClick={this.setDate}
              targettime="today"
            >
              <Typography>Today</Typography>
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.singleItemLast
              }}
              onClick={this.setDate}
              targettime="yesterday"
            >
              <Typography>Yesterday</Typography>
            </MenuItem>
            <DatePanel
              onClick={this.setDate}
              setDateRange={this.setDateRange}
            />
          </Menu>
        </div>
      </div>
    );
  }
}

DateDropdown.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  ducker.connect,
  withStyles(styles)
)(DateDropdown);
