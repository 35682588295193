import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: "25px 0 0 0"
  }
}));

export default function EnvironmentOptionsDropdown({
  value,
  onChange,
  onBlur
}) {
  const classes = useStyles();
  const inputLabel = React.useRef(null);

  const [values, setValues] = React.useState({
    executor: value
  });

  const [labelWidth, setLabelWidth] = React.useState(0);

  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  function handleChange(event) {
    setValues(oldValues => ({
      ...oldValues,
      [event.target.name]: event.target.value
    }));
    onChange(event);
  }
  return (
    <React.Fragment>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel ref={inputLabel} htmlFor="outlined-executor-simple">
          Environment
        </InputLabel>
        <Select
          value={values.executor}
          onChange={handleChange}
          onBlur={onBlur}
          input={
            <OutlinedInput
              name="executor"
              id="outlined-executor-simple"
              labelWidth={labelWidth}
            />
          }
        >
          <MenuItem value={"DEVELOPMENT"}>Development</MenuItem>
          <MenuItem value={"STAGING"}>Staging</MenuItem>
          <MenuItem value={"PRODUCTION"}>Production</MenuItem>
        </Select>
      </FormControl>
    </React.Fragment>
  );
}
