import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { withStyles } from "@material-ui/core/styles";
import { borderColor } from "../../theme";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import ducker from "./ProcessActionDropdown.ducker";
import { compose } from "recompose";
import { PROCESSES_STATE_QUERY } from "../process-state-bar/ProcessStateBar";
import { PROCESSES_QUERY } from "../process-list/ProcessList";
import { GET_PROCESS_QUERY } from "../process-details/ProcessDetails";
import TaskDialog from "./process-action-dialog/ProcessActionDialog";
const styles = theme => ({
  root: {
    display: "flex",
    zIndex: 10
  },
  paper: {
    marginRight: theme.spacing.unit * 2
  },
  button: {
    padding: 7,
    minWidth: 92,
    borderRadius: 0,
    border: `1px solid ${borderColor}`
  }
});

const SEND_ACTION = gql`
  mutation SendAction($action: String!, $processes: [ActionProcess]!) {
    sendAction(action: $action, processes: $processes) {
      statusCode
      message
    }
  }
`;

class MenuListComposition extends React.Component {
  state = {
    open: false,
    selected: false,
    openPopup: false
  };

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handlePopUpAction = () => {
    this.setState({ openPopup: true });
  };

  handleItemClick = (event, data) => {
    this.setState({ open: false });
    this.handleClose(event);

    const {
      selectedRows,
      parentProcessId,
      mutate,
      selectedTabLabel,
      executionDate,
      triggerSnackBarOnProcessAction,
      startDate,
      endDate
    } = this.props;
    let processes = null;

    const selectedTabRows = selectedRows[selectedTabLabel] || [];

    processes = selectedTabRows.map(({ processId, executionDate }) => ({
      processId,
      executionDate: executionDate.replace(" ", "T"),
      state: data.state,
      option: data.option || "",
      typeProcess: "dag"
    }));

    const refetchQueries = [
      {
        query: PROCESSES_STATE_QUERY,
        variables: {
          awaitRefetchQueries: true
        }
      }
    ];

    if (data.typeProcess === "task") {
      processes = processes.map((process, index) => ({
        ...process,
        typeProcess: selectedTabRows[index].processType.toLowerCase() || "dag",
        parentProcessId
      }));
      refetchQueries.push({
        query: GET_PROCESS_QUERY,
        variables: {
          awaitRefetchQueries: true,
          processId: parentProcessId,
          executionDate,
          typeProcess: "dag"
        }
      });
    } else {
      refetchQueries.push({
        query: PROCESSES_QUERY,
        variables: {
          awaitRefetchQueries: true,
          fetchPolicy: "network-only",
          startDate,
          endDate
        }
      });
    }

    if (processes) {
      mutate({
        variables: {
          action: "setProcess",
          processes: processes
        },
        optimisticResponse: {
          __typename: "Mutation",
          sendAction: {
            __typename: "ActionResponse",
            message: "success",
            statusCode: null
          }
        },
        refetchQueries,
        onCompleted: data => {}
      }).then(res => {
        if (res.loading) {
          return;
        }
        triggerSnackBarOnProcessAction({
          variant: "success",
          message: "Action successful"
        });
        console.log(res);
      });
    }
  };

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }
    this.setState({ open: false });
  };

  render() {
    const { classes, selectedRows, isMainView, selectedTabLabel } = this.props;
    const { open, selected } = this.state;

    const selectedTabRows = selectedRows[selectedTabLabel] || [];

    const dagActionsList = (
      <React.Fragment>
        <MenuItem
          onClick={e => {
            this.handleItemClick(e, { state: "success" });
          }}
        >
          Set to 'success'
        </MenuItem>

        <MenuItem
          onClick={e => {
            this.handleItemClick(e, { state: "running" });
          }}
        >
          Set to 'running'
        </MenuItem>

        <MenuItem
          onClick={e => {
            this.handleItemClick(e, { state: "failed" });
          }}
        >
          Set to 'failed'
        </MenuItem>
        <MenuItem
          onClick={e => {
            this.handleItemClick(e, { state: "run_process" });
          }}
        >
          Run DAG
        </MenuItem>
      </React.Fragment>
    );

    const taskActionList = (
      <React.Fragment>
        <MenuItem
          onClick={e => {
            this.handleItemClick(e, {
              state: "mark_success",
              typeProcess: "task"
            });
          }}
        >
          Mark 'success'
        </MenuItem>

        <MenuItem
          onClick={e => {
            this.handlePopUpAction();
          }}
        >
          Restart
        </MenuItem>

        <MenuItem
          onClick={e => {
            this.handleItemClick(e, { state: "kill", typeProcess: "task" });
          }}
        >
          Kill
        </MenuItem>
      </React.Fragment>
    );

    return (
      <div className={classes.root}>
        <div>
          <Button
            className={classes.button}
            buttonRef={node => {
              this.anchorEl = node;
            }}
            aria-owns={open ? "menu-list-grow" : null}
            aria-haspopup="true"
            onClick={this.handleToggle}
          >
            {selected || "Action"} {open ? <ArrowDropUp /> : <ArrowDropDown />}
          </Button>
          <Popper open={open} anchorEl={this.anchorEl} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom"
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={this.handleClose}>
                    <MenuList>
                      {selectedTabRows.length && isMainView && dagActionsList}
                      {selectedTabRows.length && !isMainView && taskActionList}
                      {!selectedTabRows.length && (
                        <MenuItem>No rows selected</MenuItem>
                      )}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>

          {this.state.openPopup && (
            <TaskDialog handleItemClick={this.handleItemClick} />
          )}
        </div>
      </div>
    );
  }
}

MenuListComposition.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default compose(
  withStyles(styles),
  ducker.connect,
  graphql(SEND_ACTION)
)(MenuListComposition);
